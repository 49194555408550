import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { UserRole } from "../../../../common/Components/User/userTypes";
import "./styles.scss";
import { useContext } from "react";
import { EvaluationCommentV2 } from "../types/EvaluationCommentV2";
import { ChangeRequestV2 } from "../types/ChangeRequestV2";
import { ChangeRequestStatus } from "../../../../foritfied/types/evaluation/ChangeRequest";
import { convertDateTo_EST_timeZone_AsString } from "../../../../common/utils/momentTimeZone";
import { PermissionsContext, PermissionsContextProps } from "@ucl/library";
import { formatField } from "./commentFormatter";

export interface CommentItemView {
  id: string;
  createdBy: string;
  createdBy_AsName: string;
  createdAt: string;
  deletedAt?: string | null;
  deletedBy?: string | null;
  comment: React.ReactNode;
  role?: UserRole;
  role_AsString?: string;
  title?: string;
}

export interface CommentListViewProps {
  comments: EvaluationCommentV2[];
  changeRequest?: ChangeRequestV2;
  deleteComment?: (
    commentId: string,
    evaluationFieldKey: string
  ) => Promise<void>;
  currentKey: string | undefined;
  hasAssociatedChangeRequests: boolean;
  canViewSystemGeneratedComments?: boolean;
}

const changeRequestCommentId = "CHANGE_REQUEST_COMMENT_ID";

const IterationEngineCommentList: React.FC<CommentListViewProps> = ({
  comments,
  changeRequest,
  hasAssociatedChangeRequests,
  deleteComment,
  currentKey,
  canViewSystemGeneratedComments,
}) => {
  const { hasPermission } = useContext(
    PermissionsContext
  ) as PermissionsContextProps<PermissionsKey>;
  const canDeleteComments = hasPermission("CanDeleteAnyComments");

  let valueChangeComments: CommentItemView[] = [];
  const shouldShowValueChangeComment =
    canViewSystemGeneratedComments &&
    hasAssociatedChangeRequests &&
    changeRequest?.to &&
    changeRequest?.status !== ChangeRequestStatus.New;

  if (shouldShowValueChangeComment) {
    const valueChangeComment: CommentItemView = {
      id: changeRequestCommentId,
      createdBy: changeRequest.valueChangedBy || changeRequest.updatedBy,
      createdBy_AsName:
        changeRequest.valueChangedBy_AsName || changeRequest.updatedBy_AsName,
      createdAt: convertDateTo_EST_timeZone_AsString(
        changeRequest.valueChangedAt || changeRequest.updatedAt
      ),
      comment: (
        <span className="change-request-comment">
          Value changed from{" "}
          <span className="change-request-comment_old">
            {formatField(changeRequest.from, changeRequest.evaluationFieldKey)}
          </span>{" "}
          to{" "}
          <span className="change-request-comment_new">
            {formatField(changeRequest.to, changeRequest.evaluationFieldKey)}
          </span>
        </span>
      ),
      title: changeRequest.evaluationFieldLabel,
    };
    valueChangeComments = [valueChangeComment];
  }

  const sortedComments = [
    ...valueChangeComments,
    ...comments.map((comment) => ({
      ...comment,
      createdAt: convertDateTo_EST_timeZone_AsString(comment.createdAt),
    })),
  ].sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );
  return (
    <div className="comment-list-scroll-container">
      <div className="comment-list-view">
        {sortedComments.map((comment) => (
          <div key={comment.id} className="comment-list-view_item">
            <div className="comment-list-view_item_line-1">
              <div className="comment-list-view_item_line-1_name">
                {comment.createdBy_AsName}
                {comment.role_AsString && ` (${comment.role_AsString})`}
              </div>
              {comment.id !== changeRequestCommentId &&
                deleteComment &&
                canDeleteComments && (
                  <div className="comment-list-view_item_line-1_delete">
                    <Button
                      minimal
                      icon={IconNames.TRASH}
                      onClick={() =>
                        deleteComment(comment.id, currentKey || "")
                      }
                    />
                  </div>
                )}
            </div>
            <div className="comment-list-view_item_line-1_time">
              {comment.createdAt}
            </div>
            <div className="comment-list-view_item_line-2">
              {comment.comment}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IterationEngineCommentList;
