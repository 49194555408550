import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { openGridCommentsDialog } from "../../Dialogs/GridComments/GridCommentsDialog";
import { wildfireUserStore } from "../../../stores/WildfireUserStore";
import { UnreadCommentsDTO } from "../../Grid/EvaluationGrid/gridColumn/WildfireCommentsGridClickableIconColumn";
import "./styles.scss";

export interface ApplicationCommentsButtonProps {
  evaluationId: string;
  wildfirePreparedId: string;
  unreadCommentsCount: string;
  fetchWildfireApplications?: () => Promise<void>;
}
export const ApplicationCommentsButton: React.FC<
  ApplicationCommentsButtonProps
> = (props) => {
  const rawFieldValue = props.unreadCommentsCount;
  let fieldValue = 0;

  if (rawFieldValue) {
    const unreadCommentsDTO = JSON.parse(rawFieldValue) as UnreadCommentsDTO[];

    if (unreadCommentsDTO) {
      fieldValue = unreadCommentsDTO
        .filter((x) => x.UserId !== wildfireUserStore.user?.id)
        .map((x) => x.UnreadCount)
        .reduce((partialSum, a) => partialSum + a, 0);
    }
  }

  return (
    <div className="application-comments-button">
      <Button
        text="Ask a Question"
        intent={Intent.PRIMARY}
        icon={IconNames.Comment}
        onClick={() =>
          openGridCommentsDialog(
            props.evaluationId,
            props.wildfirePreparedId,
            props.fetchWildfireApplications
          )
        }
      />
      {fieldValue != 0 && (
        <div className="unread-comment-count">{Math.min(fieldValue, 99)}</div>
      )}
    </div>
  );
};
