import React, { useContext, useRef } from "react";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../../../AppInsights";
import {
  PermissionsContext,
  PermissionsContextProps,
  PrimaryGrid,
  PrimaryGridModule,
} from "@ucl/library";
import variables from "../../../../common/config/variables";
import { wildfireUserStore } from "../../../stores/WildfireUserStore";
import wildfireBaseAPIClient, {
  getAuthRequestHeader,
} from "../../../lib/wildfireBaseApiClient";
import { getWildfireUserGridColumnsConfigurationByRole } from "./configurations/WildfireUserGridColumnsConfiguration";
import { UserModelGridReadDTO } from "./dtos/UserModelGridReadDTO";
import { UserModelMetricsReadDTO } from "./dtos/UserModelMetricsReadDTO";
import "../styles.scss";
import { openUpdateUserDialog } from "../../Dialogs/UpdateUser/UpdateUserDialog";

export interface WildfireUserGridProps {}

const WildfireUserGridComponent: React.FC<WildfireUserGridProps> = () => {
  const userGridRef = useRef<PrimaryGridModule>(null);

  const { hasPermission } = useContext(
    PermissionsContext
  ) as PermissionsContextProps<PermissionsKey>;

  return (
    <div className="wildfire-board">
      <PrimaryGrid<UserModelGridReadDTO, UserModelMetricsReadDTO>
        gridKey="user"
        key={"user-grid"}
        ref={userGridRef}
        apiClient={wildfireBaseAPIClient}
        odataUrl={`${variables.apiBaseUrl}odata/userodata`}
        getAuthRequestHeader={getAuthRequestHeader}
        columnsConfiguration={getWildfireUserGridColumnsConfigurationByRole(
          wildfireUserStore.user?.role
        )}
        viewsSettings={{
          gridTitle: "Users Dashboard",
          showGridViewPicker: hasPermission(
            "CanViewConfigureUsersGridColumnsButton"
          ),
          showUserDefaultView: true,
        }}
        metricsSettings={{
          url: `${variables.apiBaseUrl}api/user`,
          columns: 4,
          languageMapping: {
            countOfWildfireUsers: "Count of Users",
          },
        }}
        gridConfiguration={{
          allowExcelExport: hasPermission(
            "CanViewExportToExcelUsersGridButton"
          ),
          defaultSort: {
            field: "createdAt_AsDateOnly",
            direction: "Descending",
          },
          onRecordClick: ({ rowData }) => {
            hasPermission("CanEditUsers") &&
              openUpdateUserDialog(
                rowData.id,
                rowData.role,
                rowData.companyName,
                rowData.firstName,
                rowData.lastName,
                rowData.isActive,
                () =>
                  userGridRef.current?.getGridModule().getGridRef()?.refresh()
              );
          },
        }}
      />
    </div>
  );
};

export const WildfireUserGrid = withAITracking(
  reactPlugin,
  WildfireUserGridComponent,
  "WildfireUserGrid",
  "tracking-container"
);
