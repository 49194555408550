import ErrorWrapper from "../../../common/Components/Error/ErrorWrapper";
import { PagesLayout } from "./PagesLayout";
import { createBrowserRouter } from "react-router-dom";
import { utils } from "@ucl/library";
import { Board } from "../Board";
import { Evaluation } from "../Evaluation";
import { Checklist } from "../Checklist";
import { ReportsPage } from "../ReportsPage";
import { AdminPage } from "../AdminPage";
import { HelpPage } from "../HelpPage";
import { EvaluationBidPage } from "../EvaluationBidPage";
import { ExternalEvaluationSummaryPage } from "../ExternalEvaluationSummaryPage";
import { ReportViewerPage } from "../reports/ReportViewerPage";
import { fortifiedHomeLogout } from "../../../common/lib/msal/fortifiedHomeMsal";

const errorWrapper = (
  <div className="fortified-page">
    <ErrorWrapper logOutUser={fortifiedHomeLogout} />
  </div>
);

export const fortifiedHomeRoutePrefix = "/fh";

const uclRouteObject = [
  {
    path: `${fortifiedHomeRoutePrefix}/`,
    label: "Board Page",
    icon: undefined,
    isAvailable: true,
    showInNavigation: true,
    showInSubNavigation: true,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
    children: [
      {
        path: "",
        label: "",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: true,
        errorElement: errorWrapper,
        element: <Board />,
      },
    ],
  },
  {
    path: `${fortifiedHomeRoutePrefix}/evaluation/:id`,
    label: "Evaluation Page",
    icon: undefined,
    isAvailable: true,
    showInNavigation: true,
    showInSubNavigation: true,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
    children: [
      {
        path: "",
        label: "Evaluation Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: true,
        errorElement: errorWrapper,
        element: <Evaluation />,
      },
    ],
  },
  {
    path: `${fortifiedHomeRoutePrefix}/checklist`,
    label: "Checklist Page",
    icon: undefined,
    isAvailable: true,
    showInNavigation: true,
    showInSubNavigation: true,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
    children: [
      {
        path: "",
        label: "Checklist Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: true,
        errorElement: errorWrapper,
        element: <Checklist />,
      },
    ],
  },
  {
    path: `${fortifiedHomeRoutePrefix}/reports`,
    label: "Reports",
    icon: undefined,
    isAvailable: true,
    showInNavigation: true,
    showInSubNavigation: true,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
    children: [
      {
        path: "",
        label: "Reports",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: <ReportsPage />,
      },
      {
        path: ":reportType",
        label: "Reports",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: <ReportViewerPage />,
      },
    ],
  },
  {
    path: `${fortifiedHomeRoutePrefix}/admin`,
    label: "Admin Page",
    icon: undefined,
    isAvailable: true,
    showInNavigation: true,
    showInSubNavigation: true,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
    children: [
      {
        path: "",
        label: "Admin Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: true,
        errorElement: errorWrapper,
        element: <AdminPage />,
      },
    ],
  },
  {
    path: `${fortifiedHomeRoutePrefix}/help`,
    label: "Help Page",
    icon: undefined,
    isAvailable: true,
    showInNavigation: true,
    showInSubNavigation: true,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
    children: [
      {
        path: "",
        label: "Help Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: true,
        errorElement: errorWrapper,
        element: <HelpPage />,
      },
    ],
  },
  {
    path: `${fortifiedHomeRoutePrefix}/bid/:id`,
    label: "Bids Page",
    icon: undefined,
    isAvailable: true,
    showInNavigation: true,
    showInSubNavigation: true,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
    children: [
      {
        path: "",
        label: "Bids Page",
        isAvailable: true,
        showInNavigation: false,
        errorElement: errorWrapper,
        showInSubNavigation: true,
        element: <EvaluationBidPage />,
      },
    ],
  },
  {
    path: `${fortifiedHomeRoutePrefix}/external/evaluation-summary/:id`,
    label: "Bids Page",
    icon: undefined,
    isAvailable: true,
    showInNavigation: true,
    showInSubNavigation: true,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
    children: [
      {
        path: "",
        label: "Bids Page",
        isAvailable: true,
        showInNavigation: false,
        errorElement: errorWrapper,
        showInSubNavigation: true,
        element: <ExternalEvaluationSummaryPage />,
      },
    ],
  },
] as utils.routes.UclRouteObject[];

export const fortified_Home_browserRouter = createBrowserRouter(
  uclRouteObject,
  {
    basename: process.env.REACT_APP_PATH_PREFIX,
  }
);
