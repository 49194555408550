import { useState, useEffect } from "react";
import { ChangeRequestStatus } from "../../../foritfied/types/evaluation/ChangeRequest";
import { wildfireChangeRequestApiClient } from "../../lib/apiClients/ChangeRequest/wildfireChangeRequestApiClient";
import { ChangeRequestV2 } from "../IterationEngine/types/ChangeRequestV2";
import { get } from "lodash";
import { WildfireSchema } from "../../customHooks/useFieldSchemaFactory";

export const useEvaluationChangeRequest = (
  evaluationId: string,
  schema: WildfireSchema
) => {
  const [allChangeRequests, setAllChangeRequests] = useState<ChangeRequestV2[]>(
    []
  );

  const getChangeRequest = async (changeRequestId: string) => {
    const changeRequest = await wildfireChangeRequestApiClient.getChangeRequest(
      evaluationId,
      changeRequestId
    );

    return changeRequest;
  };

  const getChangeRequests = async () => {
    let foundChangeRequests =
      await wildfireChangeRequestApiClient.getChangeRequestsByEvaluation(
        evaluationId
      );

    // Filter out no longer existing ones
    const evaluationSchema = get(schema.schema, "Evaluation");
    foundChangeRequests = foundChangeRequests.filter((changeRequest) => {
      const field = get(
        evaluationSchema,
        changeRequest?.evaluationFieldKey || ""
      );
      return field;
    });

    // Sorting in ascending order based on the createdAt date
    const sortedChangeRequests = foundChangeRequests.sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );
    setAllChangeRequests(sortedChangeRequests);
  };

  useEffect(() => {
    getChangeRequests();
  }, []);

  const updateChangeRequest = (changeRequest?: ChangeRequestV2) => {
    if (!changeRequest) return;

    setAllChangeRequests((prev) => {
      const existingIndex = prev.findIndex((cr) => cr.id === changeRequest.id);
      if (existingIndex > -1) {
        const updatedChangeRequests = [...prev];
        updatedChangeRequests[existingIndex] = changeRequest;
        return updatedChangeRequests;
      } else {
        return [changeRequest, ...prev];
      }
    });
  };

  const resolveChangeRequest = async (changeRequestId: string) => {
    if (!evaluationId) {
      return;
    }

    await wildfireChangeRequestApiClient.resolveChangeRequest(
      evaluationId,
      changeRequestId
    );

    setAllChangeRequests([
      ...allChangeRequests.map((changeRequest) => {
        return changeRequest.id === changeRequestId
          ? {
              ...changeRequest,
              status: ChangeRequestStatus.Resolved,
              status_AsString: "Resolved",
            }
          : changeRequest;
      }),
    ]);
  };

  const unresolveChangeRequest = async (changeRequestId: string) => {
    if (!evaluationId) {
      return;
    }

    await wildfireChangeRequestApiClient.unresolveChangeRequest(
      evaluationId,
      changeRequestId
    );

    setAllChangeRequests([
      ...allChangeRequests.map((changeRequest) => {
        return changeRequest.id === changeRequestId
          ? {
              ...changeRequest,
              status: ChangeRequestStatus.New,
              status_AsString: "New",
            }
          : changeRequest;
      }),
    ]);
  };

  const unresolvedChangeRequests = allChangeRequests.filter(
    (cr) => cr.status !== ChangeRequestStatus.Resolved
  );
  const resolvedAllChangeRequests = allChangeRequests.every(
    (cr) => cr.status === ChangeRequestStatus.Resolved
  );
  const hasNoUnaddressedChangeRequests = allChangeRequests.every(
    (cr) => cr.status !== ChangeRequestStatus.New
  );
  const addressedAtLeastOneChangeRequest = allChangeRequests.some(
    (cr) => cr.status === ChangeRequestStatus.Addressed
  );
  const hasOneNewChangeRequest = allChangeRequests.some(
    (cr) => cr.status === ChangeRequestStatus.New
  );
  const newChangeRequests = allChangeRequests.filter(
    (cr) => cr.status === ChangeRequestStatus.New
  );

  const getEvaluationFieldKeysForAllUnresolvedChangeRequests = allChangeRequests
    .filter((request) => request.status !== ChangeRequestStatus.Resolved)
    .map((request) => request.evaluationFieldKey);

  const changeRequestCount = allChangeRequests.length;
  const resolvedChangeRequestCount = allChangeRequests.filter(
    (request) => request.status === ChangeRequestStatus.Resolved
  ).length;

  const resolvedChangeRequestsPercentage = () => {
    if (changeRequestCount === 0) return 0;

    return (resolvedChangeRequestCount / changeRequestCount) * 100;
  };

  const longestOutstandingChangeRequest =
    newChangeRequests && newChangeRequests.length > 0
      ? newChangeRequests
          .slice()
          .sort(
            (a, b) =>
              (b.daysInChangesRequired || 0) - (a.daysInChangesRequired || 0)
          )[0]
      : undefined;

  return {
    allChangeRequests,
    setAllChangeRequests,
    updateChangeRequest,
    resolveChangeRequest,
    unresolveChangeRequest,
    unresolvedChangeRequests,
    hasNoUnaddressedChangeRequests,
    addressedAtLeastOneChangeRequest,
    resolvedAllChangeRequests,
    getChangeRequests,
    changeRequestCount,
    resolvedChangeRequestCount,
    getEvaluationFieldKeysForAllUnresolvedChangeRequests,
    resolvedChangeRequestsPercentage,
    hasOneNewChangeRequest,
    getChangeRequest,
    longestOutstandingChangeRequest,
  };
};
