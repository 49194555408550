import "./applicant-styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import {
  FieldFormV2,
  FieldFormV2Module,
  FieldSchemaFormBuilderV2,
  Footer,
  PrimaryButton,
} from "@ucl/library";
import { useEffect, useRef, useState } from "react";
import ApplicantIterationEngineCommentPanel from "../Comment/ApplicantIterationEngineCommentPanel";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { useWildfireEvaluationComment } from "../Comment/useWildfireEvaluationComment";
import { useWildfireEvaluation } from "../../../customHooks/useWildfireEvaluation";
import { useEvaluationChangeRequest } from "../../Provider/useEvaluationChangeRequest";
import { WildfireSchemaFactoryV2 } from "../../../customHooks/useFieldSchemaFactory";
import { ChangeRequestV2 } from "../types/ChangeRequestV2";
import { get, set } from "lodash";
import { WildfireBoxContentExplorer } from "../../Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { wildfireEvaluationApiClient } from "../../../lib/apiClients/application/wildfireApplicationApiClient";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { Intent } from "@blueprintjs/core";
import { WildfireEvaluationFormModel } from "../../../forms/WildfireEvaluation/WildfireEvaluationFormModel";
import { EvaluationFormModel } from "../../../forms/EvaluationFormModel";
import { EvaluationCommentV2 } from "../types/EvaluationCommentV2";
import classNames from "classnames";
import { ChangeRequestStatus } from "../../../../foritfied/types/evaluation/ChangeRequest";

interface WildfireApplicantIterationEngineChangeRequestDetailsProps {
  evaluationId: string;
  changeRequestId: string;
  schemaFactory: WildfireSchemaFactoryV2;
  onChangeRequestCompleted?: () => void;
}

const WildfireApplicantIterationEngineChangeRequestDetails: React.FC<
  WildfireApplicantIterationEngineChangeRequestDetailsProps
> = (props) => {
  const { wildfireEvaluation, setWildfireEvaluationById } =
    useWildfireEvaluation();

  const [changeRequest, setChangeRequest] = useState<ChangeRequestV2>();

  const { getChangeRequest } = useEvaluationChangeRequest(
    props.evaluationId,
    props.schemaFactory.schema
  );

  const { comments, createComments, setSelectedKeyAndGetComments } =
    useWildfireEvaluationComment();

  const fieldFormModule = useRef<FieldFormV2Module<EvaluationFormModel>>(null);
  const [formValueChanged, setFormValueChanged] = useState<boolean>(false);
  const [formValue, setFormValue] = useState<EvaluationFormModel>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [unsavedComments, setUnsavedComments] = useState<EvaluationCommentV2[]>(
    []
  );
  const fieldSchemaFormSampleBuilder = useRef<
    FieldSchemaFormBuilderV2<EvaluationFormModel> | undefined
  >(undefined);

  const ready =
    wildfireEvaluation &&
    changeRequest &&
    fieldSchemaFormSampleBuilder.current &&
    comments &&
    formValue;

  const hasUnsavedChanges = formValueChanged || unsavedComments.length > 0;

  useEffect(() => {
    initEvaluation(props.evaluationId);
  }, []);

  useEffect(() => {
    if (wildfireEvaluation) {
      initChangeRequest(props.evaluationId, props.changeRequestId);
    }
  }, [wildfireEvaluation]);

  const initEvaluation = async (evaluationId: string) => {
    await setWildfireEvaluationById(evaluationId);
  };

  const initChangeRequest = async (
    evaluationId: string,
    changeRequestId: string
  ) => {
    if (!wildfireEvaluation) {
      throw new Error("Wildfire Evaluation not found");
    }

    // Set Form Value
    setFormValue(wildfireEvaluation as unknown as EvaluationFormModel);

    // Get Change Request
    const changeRequest = await getChangeRequest(changeRequestId);
    setChangeRequest(changeRequest);

    // Set Field
    setupBuilder(changeRequest.evaluationFieldKey);

    // Get Comments
    await setSelectedKeyAndGetComments(
      evaluationId,
      changeRequest.evaluationFieldKey
    );
  };

  const setupBuilder = (fieldKey: string) => {
    const fieldAssociatedFieldSchema = get(
      props.schemaFactory.schema.wildfireFieldSchema,
      fieldKey
    );

    const fieldKeys = [fieldKey];
    if (fieldAssociatedFieldSchema?.associatedFieldKey) {
      fieldKeys.push(fieldAssociatedFieldSchema.associatedFieldKey);
    }

    //Build Model Schema
    const builder: FieldSchemaFormBuilderV2<EvaluationFormModel> = (
      onValueChange,
      form,
      errors
    ) => {
      const schema = [
        ...props.schemaFactory.buildFieldSchemas<EvaluationFormModel>(
          "Evaluation",
          fieldKeys,
          onValueChange,
          form,
          errors,
          undefined,
          {
            FileUploader: WildfireBoxContentExplorer,
          }
        ),
      ];

      // If it have associated field, disable the field with fieldkey
      if (fieldAssociatedFieldSchema?.associatedFieldKey) {
        schema[0].disabled = true;
      }

      return schema;
    };

    fieldSchemaFormSampleBuilder.current = builder;
  };

  const handleSubmitChangeRequest = async () => {
    if (!formValue || !ready) {
      return;
    }

    const form = formValue;

    setFormValue(form);
    setIsSubmitting(true);

    //fieldPath
    const updateData = {
      applicationForm: form.wildfireApplication.applicationForm,
      homeExteriorFieldEvaluationForm:
        form.wildfireApplication.homeExteriorFieldEvaluationForm,
      landscapeFieldEvaluationForm:
        form.wildfireApplication.landscapeFieldEvaluationForm,
      otherFieldEvaluationForm:
        form.wildfireApplication.otherFieldEvaluationForm,
      roofFieldEvaluationForm: form.wildfireApplication.roofFieldEvaluationForm,
    } as unknown as WildfireEvaluationFormModel;

    Promise.all([
      wildfireEvaluationApiClient.updateWildfireEvaluationForm(
        form.wildfireApplication.id,
        updateData,
        changeRequest?.evaluationFieldKey
      ),
      createComments(
        form.id,
        unsavedComments.map((x) => x.comment),
        changeRequest.evaluationFieldKey,
        changeRequest.evaluationFieldLabel
      ),
    ])
      .then(() => {
        setUnsavedComments([]);
        AppToaster.show({
          message: "Change Request Completed",
          intent: Intent.SUCCESS,
        });
        props.onChangeRequestCompleted?.();
      })
      .catch((error) => {
        console.error(error);
        AppToaster.show({
          message: "Unexpected error occurred while saving the form",
          intent: Intent.DANGER,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div
      className={classNames(
        "wildfire-applicant-iteration-engine-change-request-details",
        {
          "change-request-resolved":
            changeRequest?.status === ChangeRequestStatus.Addressed,
        }
      )}
    >
      {!ready && <Loading />}
      {ready && (
        <>
          <div className="app-wie-change-request-details-info">
            <div>
              <FontAwesomeIcon size="sm" icon={faInfoCircle} />
              <h4>Addressing a Change Request:</h4>
              <span>Please address this change by either</span>
              <span className="app-wie-change-request-details-info-emphasis">
                uploading a new image (click + Upload button) or responding
                directly to the comment.
              </span>
            </div>
          </div>
          <div className="app-wie-change-request-details-content">
            <section className="app-wie-change-request-details-form">
              <FieldFormV2<EvaluationFormModel>
                ref={fieldFormModule}
                value={formValue}
                fieldSchemaFormBuilder={fieldSchemaFormSampleBuilder.current}
                onFieldChanged={(value, fieldValue, fieldProps) => {
                  set(
                    value,
                    (fieldProps as { fieldPath: string }).fieldPath,
                    fieldValue
                  );

                  setFormValue({ ...value });
                  setFormValueChanged(true);
                }}
                columns={1}
                isDisabled={isSubmitting}
              />
            </section>
            <section className="app-wie-change-request-details-comments">
              <ApplicantIterationEngineCommentPanel
                comments={comments}
                unsavedComments={unsavedComments}
                setUnsavedComments={setUnsavedComments}
                isDisabled={isSubmitting}
              />
            </section>
          </div>
          <Footer>
            <PrimaryButton
              className="app-wie-change-request-details-submit-button"
              content="Complete Change Request"
              onClick={handleSubmitChangeRequest}
              isLoading={isSubmitting}
              loadingSuccessContent="Addressed"
              disabled={!hasUnsavedChanges}
            />
          </Footer>
        </>
      )}
    </div>
  );
};

export default WildfireApplicantIterationEngineChangeRequestDetails;
