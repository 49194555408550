import {
  FieldLabel,
  PermissionsContext,
  PermissionsContextProps,
  fieldTypes,
} from "@ucl/library";
import { useContext, useEffect, useState } from "react";
import { FieldLabeledProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { boxTokenStore } from "../../../stores/BoxTokenStore";
import { BoxContentExplorer } from "../../../../common/Components/Fields/BoxContentExplorer";
import variables from "../../../../common/config/variables";
import "./styles.scss";
import { FormGroup } from "@blueprintjs/core";
import classnames from "classnames";
import { BoxFile } from "box-ui-elements";

export interface WildfireBoxContentExplorerValue {
  rootFolderId?: string;
  fileCount?: number;
  hasModifiedFiles?: boolean;
}

export interface WildfireBoxContentExplorerProps
  extends fieldTypes.BaseFieldProps<WildfireBoxContentExplorerValue> {
  labelProps?: FieldLabeledProps;
  examplePhoto?: string;
  examplePhoto2?: string;
  defaultToUpload?: boolean;
  legacyEvaluationShouldMakeOptional?: boolean;
  canDelete?: boolean;
}

export const WildfireBoxContentExplorer: React.FC<
  WildfireBoxContentExplorerProps
> = (props) => {
  const [ready, setReady] = useState<boolean>(false);
  const [token, setToken] = useState<string>();
  const [filesCount, setFilesCount] = useState<number>(
    props.value?.fileCount || 0
  );

  const [timestamp, updateTimestamps] = useState<string>(
    `${Date.now()}-${props.value?.rootFolderId}`
  );

  //TODO: Find a Better Place for Passing Permissions Per Field
  const { hasPermission } = useContext(
    PermissionsContext
  ) as PermissionsContextProps<PermissionsKey>;

  const setBoxTokenStore = async () => {
    if (!boxTokenStore.isTokenValid) {
      await boxTokenStore.refreshBoxToken();
    } else {
      await boxTokenStore.init();
    }

    setToken(boxTokenStore.boxToken);
  };

  useEffect(() => {
    const refreshInterval = parseInt(variables.boxFileUploaderRefreshInterval); //30 minutes

    setBoxTokenStore();

    // Set up an interval to periodically check and refresh the token
    const tokenRefreshInterval = setInterval(setBoxTokenStore, refreshInterval);
    setReady(true);

    return () => {
      clearInterval(tokenRefreshInterval);
    };
  }, []);

  useEffect(() => {
    updateTimestamps(`${Date.now()}-${props.value?.rootFolderId}`);
  }, [props.value?.fileCount]);

  const syncFiles = () => {
    if (props.value && props.onSubmit) {
      props.value.hasModifiedFiles = true;
      props.onSubmit(props.value);
    }
  };

  const hasFiles = filesCount > 0;

  return (
    <div className="wildfire-box-content-explorer-field">
      <FormGroup
        className={
          props.isRequired &&
          !hasFiles &&
          !props.legacyEvaluationShouldMakeOptional
            ? "has-required-background"
            : ""
        }
      >
        {props.label && (
          <FieldLabel
            labelName={props.label}
            tooltipDescription={props.description}
            fieldProps={{ ...props }}
            {...props.labelProps}
            disabled={props.disabled}
          />
        )}
        <div
          className={classnames({
            "wildfire-box-content-explorer-has-example-photo":
              !!props.examplePhoto,
            "wildfire-box-content-explorer-no-example-photo":
              !props.examplePhoto,
          })}
        >
          <div className="wildfire-box-content-explorer-container">
            {ready && token && (
              <BoxContentExplorer
                key={timestamp}
                token={token}
                rootFolderId={props.value?.rootFolderId}
                language="en-US"
                canCreateNewFolder={false}
                canShare={false}
                canRename={false}
                canDelete={
                  !props.disabled &&
                  (hasPermission("CanDeleteFilesInUploaders") ||
                    !!props.canDelete)
                }
                onUpload={(files: BoxFile[]) => {
                  setFilesCount((x) => x + files.length);
                  syncFiles();
                }}
                onDelete={(files: BoxFile[]) => {
                  setFilesCount((x) => x - files.length);
                  syncFiles();
                }}
                canUpload={!props.disabled}
                defaultToUpload={
                  props.defaultToUpload && !hasFiles && !props.disabled
                }
                hasAutoUpload={true}
                hasModifiedUploadButton={true}
              />
            )}
            {props.examplePhoto && (
              <div className="wildfire-box-content-explorer-example-photo-container">
                <p className="wildfire-box-content-explorer-example-photo-label">
                  <strong>Example: </strong>
                </p>
                <img
                  className="wildfire-box-content-explorer-example-photo"
                  src={props.examplePhoto}
                  alt="Example Photo 1"
                />
                <img
                  className="wildfire-box-content-explorer-example-photo"
                  src={props.examplePhoto2}
                  alt="Example Photo 2"
                />
              </div>
            )}
          </div>
        </div>
      </FormGroup>
    </div>
  );
};
