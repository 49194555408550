import { Button, Intent, TextArea } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { ChangeRequestStatus } from "../../../../foritfied/types/evaluation/ChangeRequest";
import { AppToaster } from "../../../../foritfied/components/Toast/Toast";
import { fieldTypes } from "@ucl/library";
import "./styles.scss";
import { ChangeRequestV2 } from "../types/ChangeRequestV2";
import { IconNames } from "@blueprintjs/icons";

export interface IterationEngineCommentInputProps {
  canResolveChangeRequests: boolean;
  resolveChangeRequest: (changeRequestId: string) => Promise<void>;
  onUnresolveChangeRequest: (changeRequestId: string) => Promise<void>;
  onSubmitComment?: (
    message: string,
    evaluationFieldKey: string,
    evaluationFieldLabel: string
  ) => Promise<void>;
  changeRequest?: ChangeRequestV2;
  hasAssociatedChangeRequests: boolean;
  selectedField?: fieldTypes.CoreFieldProps;
  currentKey: string | undefined;
  moveToNextChangeRequest: () => void;
  getChangeRequests: (() => Promise<void>) | undefined;
}

const IterationEngineCommentInput: React.FC<
  IterationEngineCommentInputProps
> = (props) => {
  const [isResolvingOrUnResolving, setisResolvingOrUnResolving] =
    useState(false);

  const [isSavingComment, setisSavingComment] = useState(false);

  const [unsavedComment, setUnsavedComment] = useState("");

  useEffect(() => {
    setUnsavedComment("");
  }, [props.selectedField]);

  const showResolveButton =
    props.changeRequest &&
    props.changeRequest.status !== ChangeRequestStatus.Resolved &&
    props.canResolveChangeRequests;

  const showUnresolveButton =
    props.changeRequest &&
    props.changeRequest.status === ChangeRequestStatus.Resolved &&
    props.canResolveChangeRequests;

  const fieldLabelToSave = props.hasAssociatedChangeRequests
    ? props.changeRequest?.evaluationFieldLabel
    : props.selectedField?.label;

  // Handlers
  const handleResolveClick = async () => {
    setisResolvingOrUnResolving(true);
    if (!props.changeRequest) {
      return;
    }

    await props
      .resolveChangeRequest(props.changeRequest.id)
      .then(() => {
        props.moveToNextChangeRequest();
        AppToaster.show({
          message: "Change Request Resolved Successfully!",
          intent: "success",
        });
      })
      .catch(() => {
        AppToaster.show({
          message: "Error resolving change request",
          intent: "danger",
        });
      })
      .finally(() => {
        setisResolvingOrUnResolving(false);
      });
  };

  const handleUnresolveClick = async () => {
    if (!props.changeRequest) {
      return;
    }

    setisResolvingOrUnResolving(true);
    await props
      .onUnresolveChangeRequest(props.changeRequest.id)
      .then(() => {
        AppToaster.show({
          message: "Change Request Unresolved Successfully!",
          intent: "success",
        });
      })
      .catch(() => {
        AppToaster.show({
          message: "Error Unresolving change request",
          intent: "danger",
        });
      })
      .finally(() => {
        setisResolvingOrUnResolving(false);
      });
  };

  const handleSubmitComment = async () => {
    if (props.onSubmitComment) {
      setisSavingComment(true);
      await props
        .onSubmitComment(
          unsavedComment,
          props.currentKey || "",
          fieldLabelToSave || ""
        )
        .then(() => {
          props.getChangeRequests && props.getChangeRequests();
        });
      setUnsavedComment("");
      setisSavingComment(false);
    }
  };

  return (
    <div className="comment-input">
      {props.hasAssociatedChangeRequests && (
        <>
          {showResolveButton && (
            <Button
              text="Resolve"
              className="comment-input_resolve-button"
              disabled={isResolvingOrUnResolving}
              loading={isResolvingOrUnResolving}
              intent={Intent.SUCCESS}
              onClick={async () => await handleResolveClick()}
              icon={IconNames.Tick}
            />
          )}
          {showUnresolveButton && (
            <Button
              text="Unresolve"
              intent={Intent.DANGER}
              className="comment-input_unresolve-button"
              disabled={isResolvingOrUnResolving}
              loading={isResolvingOrUnResolving}
              onClick={async () => await handleUnresolveClick()}
              icon={IconNames.DISABLE}
            />
          )}
        </>
      )}

      <TextArea
        className="comment-textarea"
        rows={5}
        value={unsavedComment}
        placeholder="Add a new comment..."
        onChange={(e) => setUnsavedComment(e.target.value)}
      />
      <div className="comment-input_actions">
        <Button
          text="Submit Comment"
          intent="primary"
          className="comment-input__submit-button"
          disabled={!unsavedComment.trim() || isResolvingOrUnResolving}
          loading={isSavingComment}
          onClick={async () => await handleSubmitComment()}
        />
      </div>
    </div>
  );
};

export default IterationEngineCommentInput;
