import { Button } from "@blueprintjs/core";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Observer } from "mobx-react-lite";
import React from "react";
import { useNavigate } from "react-router";
import { reactPlugin } from "../../AppInsights";
import variables from "../../common/config/variables";
import { useFortifiedGridViewModule } from "../../common/customHooks/useFortifiedGridViewModule";
import { FortifiedGrid } from "../components/FortifiedGrid/FortifiedGrid";
import { FortifiedGridFiltersModule } from "../components/FortifiedGridFiltersModule/FortifiedGridFiltersModule";
import { FortifiedGridViewPicker } from "../components/FortifiedGridViewsModule/FortifiedGridViewPicker";
import { Header } from "../components/Header/Header";
import { IterationEngineActions } from "../components/IterationEngine/IterationEngineActions";
import { PageSidebar } from "../components/PageSidebar/PageSidebar";
import { getGridColumnsConfigurationByRole } from "../lib/gridColumnConfigurations/boardColumnsConfiguration";
import { getGridDefaultViewByRole } from "../lib/gridColumnConfigurations/boardViewsConfiguration";
import { dialogsViewerStore } from "../stores/DialogsViewerStore";
import { evaluationStore } from "../stores/EvaluationStore";
import { fortifiedGridViewModuleStore } from "../stores/FortifiedGridViewModuleStore";
import { permissionStore } from "../stores/PermissionStore";
import { EvaluationReadDTO } from "../types/dtos/EvaluationReadDTO";
import {
  EvaluationStep_AsString,
  ProductTypes_AsString,
} from "../types/evaluation/Evaluation";
import "./styles.scss";
import { fortifiedUserStore } from "../stores/FortifiedUserStore";
import { useProductTypeHandler } from "../providers/ProductTypeHandlerProvider";

export interface BoardProps {}

const BoardComponent: React.FC<BoardProps> = () => {
  const navigate = useNavigate();
  const { routePrefix } = useProductTypeHandler();

  const goToEvaluation = (rowData: EvaluationReadDTO) => {
    const route = getRoute(rowData);
    navigate(route);
  };

  const shouldNavigateToChecklistPage = (
    productType: string,
    evaluationStatus: string
  ) => {
    const inChecklistStatus =
      evaluationStatus === EvaluationStep_AsString.Application ||
      evaluationStatus === EvaluationStep_AsString.ApplicationForms ||
      evaluationStatus === EvaluationStep_AsString.Payment;
    return (
      productType === ProductTypes_AsString.FortifiedHome && inChecklistStatus
    );
  };

  const shouldNaviateToComplexEvaluation = (rowData: EvaluationReadDTO) => {
    return (
      rowData.isComplexCommercialEvaluationBuilding === true &&
      !!rowData.complexCommercialAndMultifamilyEvaluationId
    );
  };

  const getIterationEngineRoute = (evaluationId: string) => {
    return `${routePrefix}/evaluation/${evaluationId}`;
  };

  const getRoute = (rowData: EvaluationReadDTO) => {
    if (shouldNavigateToChecklistPage(rowData.productType, rowData.step)) {
      return `${routePrefix}/checklist?fortifiedId=${rowData.fortifiedId}`;
    }
    if (shouldNaviateToComplexEvaluation(rowData)) {
      return getIterationEngineRoute(
        rowData.complexCommercialAndMultifamilyEvaluationId
      );
    }
    return getIterationEngineRoute(rowData.id);
  };

  // Fortified Grid View Module
  useFortifiedGridViewModule(
    "board-grid",
    "Board",
    getGridColumnsConfigurationByRole,
    getGridDefaultViewByRole
  );

  return (
    <div className="fortified-page board-page">
      {/* Dont want page level observer */}
      <Observer>
        {() => <Header renderActions={() => <IterationEngineActions />} />}
      </Observer>
      <div className="content">
        <PageSidebar activePage="home" />
        <div className="page-main-content">
          <section className="board-toolbar">
            <ul className="board-toolbar-left">
              <li>
                {/* Fortified Grid Views Module - Picker */}
                <FortifiedGridViewPicker
                  key={fortifiedGridViewModuleStore.currentGridViewKey}
                />
              </li>
            </ul>
            <ul className="board-toolbar-right">
              <li>
                {/* Fortified Grid Filters Module */}
                <FortifiedGridFiltersModule />
              </li>
              {permissionStore.canCreateNewEvaluation && (
                <li>
                  <Button
                    className="evaluation-button"
                    text={
                      evaluationStore.getProductTypeByUserRole === "commercial"
                        ? "New Application"
                        : "New Evaluation"
                    }
                    icon="add"
                    intent="primary"
                    onClick={() =>
                      dialogsViewerStore.setIsEvaluationCreationDialog(true)
                    }
                  />
                </li>
              )}
            </ul>
          </section>
          <section className="board-grid-container">
            {/* Fortified Grid */}
            <Observer>
              {() => (
                <>
                  {!!fortifiedGridViewModuleStore.currentGridView && (
                    <FortifiedGrid<EvaluationReadDTO>
                      key={fortifiedGridViewModuleStore.currentGridViewKey}
                      dataSource={`${
                        variables.apiBaseUrl as string
                      }odata/evaluationodatagrid`}
                      columnsConfiguration={[
                        ...fortifiedGridViewModuleStore.currentGridView
                          .columnConfiguration,
                      ]}
                      filterConfiguration={[
                        ...(fortifiedGridViewModuleStore.currentGridView
                          .filterConfiguration || []),
                      ]}
                      sortConfiguration={[
                        ...(fortifiedGridViewModuleStore.currentGridView
                          .sortConfiguration || []),
                      ]}
                      gridConfiguration={{
                        allowTextWrap: true,
                        textWrapSettings: { wrapMode: "Header" },
                        defaultSort: {
                          field: "createdAt",
                          direction: fortifiedUserStore.isAuditorTrainee
                            ? "Ascending"
                            : "Descending",
                        },
                        exportFilename: "Evaluations.xlsx",
                        onRecordClick: ({ rowData }) => {
                          goToEvaluation(rowData);
                        },
                      }}
                    />
                  )}
                </>
              )}
            </Observer>
          </section>
        </div>
      </div>
    </div>
  );
};

export const Board = withAITracking(
  reactPlugin,
  BoardComponent,
  "Board",
  "tracking-container"
);
