import "./admin-styles.scss";
import IterationEngineNavigator from "./Navigator/AdminIterationEngineNavigator";
import {
  FieldSchemaFactoryProvider,
  WildfireSchemaFactoryV2,
} from "../../customHooks/useFieldSchemaFactory";
import IterationEngineCommentPanel from "./Comment/IterationEngineCommentPanel";
import { wildfireUserStore } from "../../stores/WildfireUserStore";
import IterationEngineHeader from "./Header/IterationEngineHeader";
import WildfireIterationEngineForms from "./Forms/WildfireAdminIterationEngineForms";
import useWildfireIterationEngine, {
  PlusTabId,
} from "./useWildfireIterationEngine";
import { useEffect, useState } from "react";

export interface WildfireAdminIterationEngineProps {
  evaluationId?: string;
  wildfireSchemaFactory: WildfireSchemaFactoryV2;
}

const WildfireAdminIterationEngine: React.FC<
  WildfireAdminIterationEngineProps
> = (props) => {
  const {
    selectedIterationEngineFormTabId,
    setSelectedIterationEngineFormTabId,
    formFieldEvaluationDecisioning,
    formTabs,
    wildfireEvaluation,
    refreshWildfireEvaluation,
    sendForward,
    isSendForwardLoading,
    approve,
    isApproveLoading,
    reject,
    isRejectLoading,
    requestChanges,
    regenerateCertificate,
    downloadCertificate,
    isRequestChangesLoading,
    comments,
    createComment,
    deleteComment,
    setSelectedFieldAndGetComments,
    setSelectedKeyAndGetComments,
    selectedField,
    selectedKey,
    allChangeRequests,
    resolvedAllChangeRequests,
    getChangeRequests,
    getEvaluationFieldKeysForAllUnresolvedChangeRequests,
    hasNoUnaddressedChangeRequests,
    hasOneNewChangeRequest,
    canEditForm,
    unresolvedChangeRequests,
    fetchFormFieldEvaluationDecisioning,
    selectedIterationEngineFormTabRequirePlusFields,
    getCountOfNonCompliantFields,
    longestOutstandingChangeRequest,
  } = useWildfireIterationEngine(props.evaluationId);

  const [evaluationFormInitialized, setEvaluationFormInitialized] =
    useState(false);

  useEffect(() => {
    setEvaluationFormInitialized(false);
  }, [props.evaluationId, selectedIterationEngineFormTabId]);

  return (
    <section className="wildfire-admin-iteration-engine">
      {wildfireEvaluation && (
        <FieldSchemaFactoryProvider
          schemaFactory={props.wildfireSchemaFactory}
          formOverrides={{
            fieldDecisions: formFieldEvaluationDecisioning,
            isPlus: selectedIterationEngineFormTabId == PlusTabId,
            onLabelClick: (fieldProps) =>
              setSelectedFieldAndGetComments?.(
                wildfireEvaluation.id,
                fieldProps
              ),
            iterationEngineProps: {
              fieldKeysWithChangeRequests:
                getEvaluationFieldKeysForAllUnresolvedChangeRequests,
              shouldEnableForm: canEditForm,
              changeRequests: allChangeRequests,
            },
          }}
        >
          <div className="wildfire-iteration-engine">
            <div className="wildfire-iteration-engine__header">
              <IterationEngineHeader
                evaluationId={wildfireEvaluation.id}
                auditorId={wildfireEvaluation.auditorId}
                wildfireApplicationId={
                  wildfireEvaluation.wildfireApplication
                    .wildfirePreparedApplicationId
                }
                unreadCommentsCount={wildfireEvaluation.unreadCommentsCount}
                wildfireApplicationOwnerId={
                  wildfireEvaluation?.wildfireApplication.ownerId
                }
                step={wildfireEvaluation.wildfireApplication.step}
                status={wildfireEvaluation.status_AsString}
                isWildfireAdmin={wildfireUserStore.isWildfireAdmin}
                resolvedAllChangeRequest={resolvedAllChangeRequests}
                hasOneNewChangeRequest={hasOneNewChangeRequest}
                hasNoUnaddressedChangeRequests={hasNoUnaddressedChangeRequests}
                refreshWildfireApplication={refreshWildfireEvaluation}
                sendForward={sendForward}
                isSendForwardLoading={isSendForwardLoading}
                approve={approve}
                isApproveLoading={isApproveLoading}
                reject={reject}
                isRejectLoading={isRejectLoading}
                requestChanges={requestChanges}
                isRequestChangesLoading={isRequestChangesLoading}
                downloadCertificate={downloadCertificate}
                regenerateCertificate={regenerateCertificate}
                externalFolderId={wildfireEvaluation.externalFolderId}
                legacyEvaluationSource={
                  wildfireEvaluation.legacyEvaluationSource
                }
                auditorNotes={wildfireEvaluation.auditorNotes}
                isWithdrawn={wildfireEvaluation.isWithdrawn}
                shouldShowAutoRejectBanner={
                  wildfireEvaluation.wildfireApplication
                    .shouldShowAutoRejectBanner
                }
                longestOutstandingChangeRequest={
                  longestOutstandingChangeRequest
                }
              />
            </div>
            <div className="wildfire-iteration-engine__content">
              <div className="wildfire-iteration-engine__left-panel">
                <IterationEngineNavigator
                  evaluationStep={wildfireEvaluation.wildfireApplication.step}
                  evaluationStatus={wildfireEvaluation.status_AsString}
                  selectedTabId={selectedIterationEngineFormTabId}
                  setSelectedTabId={setSelectedIterationEngineFormTabId}
                  formTabs={formTabs}
                  formFieldEvaluationDecisioning={
                    formFieldEvaluationDecisioning
                  }
                  getCountOfNonCompliantFields={(isPlus?: boolean) =>
                    getCountOfNonCompliantFields(
                      props.wildfireSchemaFactory.schema.wildfireFieldSchema,
                      isPlus
                    )
                  }
                />
              </div>
              <div className="wildfire-iteration-engine__center-panel">
                <WildfireIterationEngineForms
                  fieldsContainerClassName="evaluation-forms-container"
                  selectedTabId={selectedIterationEngineFormTabId}
                  changeRequests={allChangeRequests}
                  evaluationId={wildfireEvaluation.id}
                  evaluationStep={wildfireEvaluation.step}
                  isPlus={selectedIterationEngineFormTabRequirePlusFields}
                  getChangeRequests={getChangeRequests}
                  fetchFormFieldEvaluationDecisioning={
                    fetchFormFieldEvaluationDecisioning
                  }
                  onEvaluationFormInitialized={() => {
                    setEvaluationFormInitialized(true);
                  }}
                />
              </div>
              <div className="wildfire-iteration-engine__right-panel">
                <IterationEngineCommentPanel
                  fieldsContainerClassName="evaluation-forms-container"
                  isOpen={evaluationFormInitialized}
                  changeRequests={allChangeRequests}
                  unresolvedChangeRequests={unresolvedChangeRequests}
                  setSelectedIterationEngineFormTabId={
                    setSelectedIterationEngineFormTabId
                  }
                  selectedIterationEngineFormTabId={
                    selectedIterationEngineFormTabId
                  }
                  selectedKey={selectedKey}
                  comments={comments}
                  createComment={(
                    comment,
                    evaluationFieldKey,
                    evaluationFieldLabel
                  ) =>
                    createComment(
                      wildfireEvaluation.id,
                      comment,
                      evaluationFieldKey,
                      evaluationFieldLabel
                    )
                  }
                  selectedField={selectedField}
                  deleteComment={(commentId, evaluationFieldKey) =>
                    deleteComment(
                      wildfireEvaluation.id,
                      commentId,
                      evaluationFieldKey
                    )
                  }
                  setSelectedKeyAndGetComments={(fieldKey) =>
                    setSelectedKeyAndGetComments!(
                      wildfireEvaluation.id,
                      fieldKey
                    )
                  }
                />
              </div>
            </div>
          </div>
        </FieldSchemaFactoryProvider>
      )}
    </section>
  );
};

export default WildfireAdminIterationEngine;
