import { ContentExplorerProps } from "box-ui-elements";
import { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ReactDOMServer from "react-dom/server";
import { getScrollContainer } from "../../../wildfire/utils/getScrollContainer";

// For Javascript implemntation, see: https://github.com/box/box-content-preview
export interface BoxContentExplorerProps extends ContentExplorerProps {
  defaultToUpload?: boolean;
  hasAutoUpload?: boolean;
  hasModifiedUploadButton?: boolean;
}

export const BoxContentExplorer: React.FC<BoxContentExplorerProps> = (
  props
) => {
  const contentExplorerRef = useRef<HTMLDivElement>(null);

  const containerClassName = `box-content-explorer-container-${props.rootFolderId}`;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const contentExplorer = new Box.ContentExplorer();
    contentExplorer.show(props.rootFolderId, props.token, {
      container: `.${containerClassName}`,
      ...props,
    });
    if (props.defaultToUpload) {
      waitForReadyToSetUploadState();
    }
    if (props.hasAutoUpload) {
      waitForAutoUploadState();
    }

    if (props.hasModifiedUploadButton) {
      waitForUploadButton();
    }
  }, [
    props.token,
    props.rootFolderId,
    props.canDelete,
    props.canDownload,
    props.canPreview,
    props.canRename,
    props.canUpload,
    props.defaultToUpload,
  ]);

  const waitForReadyToSetUploadState = () => {
    const observer: MutationObserver = new MutationObserver(() =>
      handleUploadMutation(observer)
    );
    observer.observe(contentExplorerRef.current as Node, {
      childList: true,
      subtree: true,
    });
  };

  const waitForUploadButton = () => {
    const observer: MutationObserver = new MutationObserver(() =>
      handleUploadButtonMutation(observer)
    );
    observer.observe(contentExplorerRef.current as Node, {
      childList: true,
      subtree: true,
    });
  };

  const waitForAutoUploadState = () => {
    const observer: MutationObserver = new MutationObserver(() => autoUpload());
    observer.observe(contentExplorerRef.current as Node, {
      childList: true,
      subtree: true,
    });
  };

  const handleUploadMutation = (observer: MutationObserver) => {
    if (setUploadState()) {
      observer.disconnect();
    }
  };

  const handleUploadButtonMutation = (observer: MutationObserver) => {
    if (injectUploadButtonText()) {
      observer.disconnect();
    }
  };

  // Returns the first parent of an element that has a scrollbar

  const setUploadState = (): boolean => {
    // When main Box Content Explorer container is rendered, it is ready to receive keyboard events
    const container = contentExplorerRef.current?.querySelector(
      `div.bce-content`
    ) as HTMLDivElement;

    if (container) {
      // Emulate the keyboard shortcut that navigates to the upload state
      container.dispatchEvent(
        new KeyboardEvent("keydown", { key: "g", bubbles: true })
      );
      container.dispatchEvent(
        new KeyboardEvent("keydown", { key: "u", bubbles: true })
      );
      // The above keyboard events were causing the page to scroll down, which we don't want
      // To fix this, we find the scroll container and set it to the top position
      const scrollableParent = getScrollContainer(
        contentExplorerRef.current as HTMLElement
      );
      scrollableParent?.scrollTo(0, 0);
      return true;
    }
    return false;
  };

  const autoUpload = () => {
    // When main Box Content Explorer container is rendered, it is ready to receive keyboard events
    const uploadButton = contentExplorerRef.current?.querySelector(
      `button.btn-primary`
    ) as HTMLButtonElement;

    if (uploadButton && !uploadButton.ariaDisabled) {
      uploadButton.click();

      setTimeout(() => {
        autoClose();
      }, 5000);
    }
  };

  const autoClose = () => {
    const closeButtonContainer = contentExplorerRef.current?.querySelector(
      ".bcu-footer-left"
    ) as HTMLDivElement;
    const closeButton = closeButtonContainer?.querySelector(
      "button.btn span.btn-content"
    ) as HTMLSpanElement;

    if (
      closeButton &&
      closeButton.textContent === "Close" &&
      !closeButton.parentElement?.ariaDisabled &&
      closeButton.parentElement
    ) {
      closeButton.parentElement.click();
    }
  };

  const injectUploadButtonText = (): boolean => {
    const uploadButton = contentExplorerRef.current?.querySelector(
      `button.btn.be-btn-add`
    ) as HTMLButtonElement;
    if (uploadButton) {
      const span = uploadButton.querySelector(".btn-content");
      if (span && !span.textContent) {
        const iconHtml = ReactDOMServer.renderToString(
          <FontAwesomeIcon icon={faPlus} />
        );
        span.innerHTML += `${iconHtml} Upload`;

        return true;
      }
    }
    return false;
  };

  return (
    <div className="box-content-explorer">
      <div ref={contentExplorerRef} className={containerClassName}></div>
    </div>
  );
};
