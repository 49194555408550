import * as msal from "@azure/msal-browser";
import _envVar from "../../config/variables";
import { baseMsalConfig, ibhsB2CPolicies, loginRequest } from ".";

// Specific configuration for Fortified Commercial Multifamily, extending the base configuration
export const fortifiedCommercialMultifamilyMsalConfig = {
  ...baseMsalConfig,
  auth: {
    ...baseMsalConfig.auth,
    authority:
      ibhsB2CPolicies.authorities.signUpSignIn
        .fortifiedCommercialMultifamilyAuthority,
    redirectUri: _envVar.fortified_Commercial_Multifamily_RedirectUri,
    postLogoutRedirectUri:
      _envVar.fortified_Commercial_Multifamily_PostLogoutRedirectUri,
  },
};

export const fortifiedCommercialMultifamilyMsalInstance =
  new msal.PublicClientApplication(fortifiedCommercialMultifamilyMsalConfig);

export const fortifiedCommercialMultifamilyLogout = async () => {
  const loggedInAccount =
    fortifiedCommercialMultifamilyMsalInstance.getActiveAccount();

  await fortifiedCommercialMultifamilyMsalInstance.logoutRedirect({
    account: loggedInAccount,
    postLogoutRedirectUri:
      fortifiedCommercialMultifamilyMsalConfig.auth.postLogoutRedirectUri,
  });
};

export const getFortifiedCommercialMultifamilyAccessToken = async () => {
  try {
    const token =
      await fortifiedCommercialMultifamilyMsalInstance.acquireTokenSilent(
        loginRequest
      );
    return token.accessToken;
  } catch (e) {
    fortifiedCommercialMultifamilyMsalInstance.loginRedirect(loginRequest);
  }
};
