import { FC } from "react";
import { ExistingGridCommentsView } from "./ExistingGridCommentsView";
import { NewGridCommentForm } from "./NewGridCommentForm";
import useGridComments from "./useGridComments";

export interface GridCommentsProps {
  evaluationId: string;
  refreshGrid?: () => Promise<void>;
  onCancel: () => void;
}

export const GridCommentsComponent: FC<GridCommentsProps> = (
  props: GridCommentsProps
) => {
  const {
    localEvaluationComments,
    createComment,
    markCommentAsRead,
    markCommentAsUnread,
    updateComment,
    deleteComment,
    isInitialized,
  } = useGridComments(props);

  return (
    <div className="wildfire-grid-comments-dialog">
      <ExistingGridCommentsView
        comments={localEvaluationComments}
        markCommentAsRead={markCommentAsRead}
        markCommentAsUnread={markCommentAsUnread}
        updateComment={updateComment}
        deleteComment={deleteComment}
        isInitialized={isInitialized}
      />

      <NewGridCommentForm
        onCancel={props.onCancel}
        onSubmit={async (comment) =>
          await createComment(props.evaluationId, comment)
        }
      />
    </div>
  );
};
