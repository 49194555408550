import { useState, useEffect, useRef } from "react";
import { CommentContainerProps } from "../IterationEngineCommentPanel";
import { ChangeRequestStatus } from "../../../../../foritfied/types/evaluation/ChangeRequest";
import { wildfireUserStore } from "../../../../stores/WildfireUserStore";
import {
  WildfireDesignationLevel,
  useFieldSchemaFactory,
} from "../../../../customHooks/useFieldSchemaFactory";
import { BaseTabId, PlusTabId } from "../../useWildfireIterationEngine";
import { first, isNil } from "lodash";
import { ChangeRequestV2 } from "../../types/ChangeRequestV2";

export const getThirdLevelNesting = (
  inputString: string
): string | undefined => {
  const separator = "__";
  const parts = inputString.split(separator);

  // Check if the array has at least three elements (for third level)
  if (parts.length >= 3) {
    return parts[2]; // Return the third element (index 2)
  }
};

const activeClassName = "change-request-navigator-element-active";
const holdClassName = "change-request-navigator-element-hold";

const useChangeRequestNavigator = (
  props: Pick<
    CommentContainerProps,
    | "selectedKey"
    | "selectedField"
    | "setSelectedIterationEngineFormTabId"
    | "selectedIterationEngineFormTabId"
    | "setSelectedKeyAndGetComments"
    | "isOpen"
  >,
  allChangeRequests: ChangeRequestV2[],
  fieldsContainerClassName: string
) => {
  const { schemaFactory } = useFieldSchemaFactory();

  const [currentChangeRequestKey, setCurrentChangeRequestKey] = useState<
    string | undefined | null
  >(null);
  const [isCurrentKeyOnPage, setIsCurrentKeyOnPage] = useState<boolean>();

  const [currentChangeRequest, setCurrentChangeRequest] =
    useState<ChangeRequestV2>();

  const [navigationChangeRequests, setNavigationChangeRequests] = useState<
    ChangeRequestV2[]
  >([]);

  const [
    navigationChangeRequestsOnPageKeys,
    setNavigationChangeRequestsOnPageKeys,
  ] = useState<string[]>([]);

  const changeRequestObserverRef = useRef<IntersectionObserver>();
  const changeRequestElementRef = useRef<Element>();

  useEffect(() => {
    setCurrentChangeRequestKey(undefined);
  }, [props.selectedIterationEngineFormTabId]);

  useEffect(() => {
    // If we loaded the requests, and there are requests on the page, navigate to the one assigned. default : 0
    if (
      navigationChangeRequests.length > 0 &&
      navigationChangeRequestsOnPageKeys?.length > 0
    ) {
      let reqKey = currentChangeRequestKey;
      if (isNil(reqKey)) {
        const unresolved = getUnresolved();
        if (!unresolved || unresolved.length === 0) {
          const firstRequest = navigationChangeRequests.at(0)!;
          reqKey = firstRequest.evaluationFieldKey;
        } else {
          const firstRequest = unresolved.at(0)!;
          reqKey = firstRequest.evaluationFieldKey;
        }
      }
      navigateToChangeRequest(reqKey, false);
    }
  }, [navigationChangeRequests]);

  useEffect(() => {
    if (isNil(currentChangeRequestKey)) {
      unAssignChangeRequest();
      return;
    }
  }, [currentChangeRequestKey]);

  useEffect(() => {
    autoNavigateToChangeRequestOnInit();
  }, []);

  useEffect(() => {
    if (props.isOpen) {
      initNavigationChangeRequests();
    }
  }, [props.isOpen, allChangeRequests]);

  useEffect(() => {
    if (!props.selectedField) {
      return;
    }
    if (props.selectedField?.fieldKey) {
      navigateToChangeRequest(props.selectedField?.fieldKey, false);
    } else {
      setCurrentChangeRequestKey(undefined);
    }
  }, [props.selectedField]);

  useEffect(() => {
    if (!props.selectedKey || props.selectedKey === currentChangeRequestKey) {
      return;
    }
    if (props.selectedKey) {
      navigateToChangeRequest(props.selectedKey, false);
    } else {
      setCurrentChangeRequestKey(undefined);
    }
  }, [props.selectedKey]);

  const assignChangeRequest = async (key?: string) => {
    if (!key) {
      return undefined;
    }

    const request = navigationChangeRequests.find(
      (cr) => cr.evaluationFieldKey === key
    );

    if (!request) {
      return undefined;
    }

    setCurrentChangeRequest(request);

    if (request) {
      const element = getElementsByFieldKeys([request.evaluationFieldKey]);

      if (element.length === 1) {
        setIsCurrentKeyOnPage(true);
      } else {
        setIsCurrentKeyOnPage(false);
      }
    }

    return request;
  };

  const unAssignChangeRequest = async () => {
    setCurrentChangeRequest(undefined);
    setIsCurrentKeyOnPage(false);
    props.setSelectedKeyAndGetComments?.(undefined);
  };

  const initNavigationChangeRequests = async () => {
    const requestElements = allChangeRequests.map((cr) => {
      const elementGroup = {
        key: cr.evaluationFieldKey,
        request: cr,
        element: getElementsByFieldKeys([cr.evaluationFieldKey]),
        index: undefined as number | undefined,
      };
      return elementGroup;
    });

    const allFieldElements = document.querySelectorAll(
      `.${fieldsContainerClassName} .ucl-field-label-clickable`
    );

    // Order the change requests by the order of the elements on the page
    requestElements.forEach((req) => {
      const element = first(req.element);
      if (element) {
        const index = Array.from(allFieldElements).indexOf(element);
        req.index = index;
      }
    });

    // Sort change requests by elements on the page
    const changeRequestsOnPage = requestElements
      .filter((req) => req.index !== undefined)
      .sort((a, b) => (a.index || 0) - (b.index || 0))
      .map((req) => req.request);

    const changeRequestsNotOnPage = requestElements
      .filter((req) => req.index === undefined)
      .map((req) => req.request);

    const onPageKeys = changeRequestsOnPage.map((cr) => cr.evaluationFieldKey);
    setNavigationChangeRequestsOnPageKeys(onPageKeys);

    const sortedChangeRequests = [
      ...changeRequestsOnPage,
      ...changeRequestsNotOnPage,
    ];

    if (
      currentChangeRequestKey &&
      onPageKeys &&
      !onPageKeys.includes(currentChangeRequestKey)
    ) {
      setCurrentChangeRequestKey(first(onPageKeys));
    }

    setNavigationChangeRequests(sortedChangeRequests);
  };

  const getUnresolved = () => {
    if (props.selectedIterationEngineFormTabId === PlusTabId) {
      return navigationChangeRequests?.filter(
        (cr) =>
          schemaFactory?.schema.wildfireFieldSchema?.[cr.evaluationFieldKey]
            ?.designationLevel === WildfireDesignationLevel.Plus &&
          cr.status !== ChangeRequestStatus.Resolved
      );
    } else {
      return navigationChangeRequests?.filter(
        (cr) =>
          schemaFactory?.schema.wildfireFieldSchema?.[cr.evaluationFieldKey]
            ?.designationLevel !== WildfireDesignationLevel.Plus &&
          cr.status !== ChangeRequestStatus.Resolved
      );
    }
  };

  const autoNavigateToChangeRequestOnInit = async () => {
    props.setSelectedIterationEngineFormTabId(BaseTabId);
  };

  const getElementsByFieldKeys = (fieldKeys: string[]): Element[] => {
    if (!fieldKeys || fieldKeys.length === 0) {
      return [];
    }

    const elements: Element[] = [];

    fieldKeys.forEach((key) => {
      const elementCollection = document.getElementsByClassName(key);

      if (elementCollection.length === 1) {
        const el = first(elementCollection);
        if (el) {
          elements.push(el);
        }
      }
    });
    return elements || [];
  };

  const hasAssociatedChangeRequests = navigationChangeRequests.some(
    (cr) =>
      cr.evaluationFieldKey ===
      (currentChangeRequest?.evaluationFieldKey ||
        props.selectedField?.fieldKey)
  );

  const hasNoNewChangeRequests = navigationChangeRequests.every(
    (cr) => cr.status !== ChangeRequestStatus.New
  );

  const applicantChangeRequestStatusLabel =
    wildfireUserStore.isWildfireAdmin &&
    currentChangeRequest?.status_AsString === "Addressed"
      ? "Unresolved"
      : currentChangeRequest?.status_AsString || "New";

  const navigateToPreviousChangeRequest = async () => {
    if (isNil(currentChangeRequestKey)) {
      return;
    }

    // Get the request key after the current one given athe current key
    const currentChangeRequestIndex = navigationChangeRequests.findIndex(
      (cr) => cr.evaluationFieldKey === currentChangeRequestKey
    );

    const newIndex = Math.max(currentChangeRequestIndex - 1, 0);

    // Get Key
    const prevRequest = navigationChangeRequests.at(newIndex);
    if (prevRequest) {
      await navigateToChangeRequest(prevRequest.evaluationFieldKey, true);
    }
  };

  const navigateToNextChangeRequest = async () => {
    if (isNil(currentChangeRequestKey)) {
      return;
    }

    // Get the request key after the current one given athe current key
    const currentChangeRequestIndex = navigationChangeRequests.findIndex(
      (cr) => cr.evaluationFieldKey === currentChangeRequestKey
    );

    const newIndex = Math.min(
      currentChangeRequestIndex + 1,
      navigationChangeRequests.length - 1
    );

    // Get Key
    const nextRequest = navigationChangeRequests.at(newIndex);
    if (nextRequest) {
      await navigateToChangeRequest(nextRequest.evaluationFieldKey, true);
    }
  };

  const navigateToFirstChangeRequest = async () => {
    const firstRequest = navigationChangeRequests.at(0);
    if (firstRequest) {
      await navigateToChangeRequest(firstRequest.evaluationFieldKey, true);
    }
  };

  const navigateToChangeRequest = async (
    key: string,
    autoChangeFormTab: boolean
  ) => {
    if (!key) {
      return;
    }

    let formFieldKey: string | undefined = key;
    if (autoChangeFormTab) {
      formFieldKey = setSelectedTabIdAndGetNewFormFieldKey(key);
    }

    unAssignChangeRequest();

    await fetchFieldCommentsByChangeRequestIndexAndScrollToChangeRequest(
      formFieldKey
    );

    setCurrentChangeRequestKey(formFieldKey);
    assignChangeRequest(formFieldKey);
  };

  const setSelectedTabIdAndGetNewFormFieldKey = (
    key: string
  ): string | undefined => {
    // Check if the field is plus
    const fieldIsPlus =
      schemaFactory?.schema.wildfireFieldSchema?.[key]?.designationLevel ===
        WildfireDesignationLevel.Plus || false;

    const formIsPlus = props.selectedIterationEngineFormTabId === PlusTabId;

    props.setSelectedIterationEngineFormTabId(
      fieldIsPlus ? PlusTabId : BaseTabId
    );

    if (!(fieldIsPlus === formIsPlus)) {
      setCurrentChangeRequestKey(undefined);
      return undefined;
    }

    return key;
  };

  const fetchFieldCommentsByChangeRequestIndexAndScrollToChangeRequest = async (
    formFieldKey: string | undefined
  ) => {
    if (!formFieldKey) {
      props.setSelectedKeyAndGetComments?.(undefined);
      return;
    }

    const changeRequest = navigationChangeRequests.find(
      (cr) => cr.evaluationFieldKey === formFieldKey
    );

    if (changeRequest) {
      const fieldKey = changeRequest.evaluationFieldKey;
      await props.setSelectedKeyAndGetComments?.(fieldKey).then(() => {
        goToChangeRequest(fieldKey, false);
      });
    } else if (formFieldKey) {
      goToChangeRequest(formFieldKey, false);
    }
  };

  const goToChangeRequest = (fieldKey: string, instant?: boolean): boolean => {
    if (fieldKey) {
      const elements = getElementsByFieldKeys([fieldKey]);
      if (elements.length !== 1) {
        return false;
      }

      const el = first(elements);
      if (el) {
        const parentEl = el.closest(".field-form-field");
        if (parentEl) {
          scrollToElement(parentEl, instant);
        } else {
          scrollToElement(el, instant);
        }
      }
    }
    return false;
  };

  const clearPrevElement = () => {
    if (changeRequestElementRef.current) {
      changeRequestElementRef.current.classList.remove(activeClassName);
      changeRequestObserverRef.current?.unobserve(
        changeRequestElementRef.current
      );
    }
  };

  const scrollToElement = (element?: Element, instant?: boolean): boolean => {
    if (!element) {
      return false;
    }

    // Scroll
    element.scrollIntoView({
      behavior: instant ? "auto" : "smooth",
      block: "start",
      inline: "start",
    });

    // Observe Viewport Intersection
    if (!changeRequestObserverRef.current) {
      changeRequestObserverRef.current = changeRequestObserver;
    }
    // Clear Prev
    clearPrevElement();

    setTimeout(() => {
      // Re-Scroll to ensure the element is in view
      element.scrollIntoView({
        behavior: instant ? "auto" : "smooth",
        block: "start",
        inline: "start",
      });

      // Assign
      element.classList.add(activeClassName);
      changeRequestElementRef.current = element;
      changeRequestObserverRef.current?.observe(element);

      // Re-Scroll to ensure the element is in view
      setTimeout(() => {
        element.scrollIntoView({
          behavior: instant ? "auto" : "smooth",
          block: "start",
          inline: "start",
        });
      }, 300);
    }, 600);

    return true;
  };

  const changeRequestObserver = new IntersectionObserver((entries) => {
    // Observing
    entries.forEach((entry) => {
      let isInitializing = false;

      if (!entry.target.classList.contains(activeClassName)) {
        isInitializing = true;
        // Add active class
        entry.target.classList.add(activeClassName);
      }

      if (!isInitializing && entry.intersectionRatio < 0.02) {
        if (!entry.target.classList.contains(holdClassName)) {
          changeRequestObserverRef.current?.unobserve(entry.target);
          setTimeout(() => {
            // Analyze if we should remove active class
            entry.target.classList.add(holdClassName);
            changeRequestObserverRef.current?.observe(entry.target);
          }, 600);
        } else {
          // Remove active class
          entry.target.classList.remove(activeClassName);
          entry.target.classList.remove(holdClassName);
          changeRequestObserverRef.current?.unobserve(entry.target);
        }
      }
    });
  });

  const moveToNextChangeRequest = () => {
    if (isNil(currentChangeRequestKey)) {
      return;
    }

    const currentIndex = navigationChangeRequests.findIndex(
      (cr) => cr.evaluationFieldKey === currentChangeRequestKey
    );
    let nextIndex = currentIndex + 1;
    let foundNewChangeRequest = false;

    // Loop through all change requests starting from the next index
    for (let i = 0; i < navigationChangeRequests.length; i++) {
      const indexToCheck = (nextIndex + i) % navigationChangeRequests.length; // Wrap around if we reach the end
      const changeRequest = navigationChangeRequests[indexToCheck];

      // Check if the change request is not Resolved
      if (changeRequest.status !== ChangeRequestStatus.Resolved) {
        nextIndex = indexToCheck;
        foundNewChangeRequest = true;
        break; // Exit the loop once a new change request is found
      }
    }

    if (foundNewChangeRequest) {
      const req = navigationChangeRequests.at(nextIndex);
      if (req) {
        navigateToChangeRequest(req.evaluationFieldKey, true);
      }
    }
  };

  return {
    moveToNextChangeRequest,
    hasAssociatedChangeRequests,
    hasNoNewChangeRequests,
    navigateToPreviousChangeRequest,
    currentChangeRequestIndex: navigationChangeRequests.findIndex(
      (cr) => cr.evaluationFieldKey === currentChangeRequestKey
    ),
    currentChangeRequestKey,
    navigateToNextChangeRequest,
    applicantChangeRequestStatusLabel,
    currentChangeRequest,
    fieldsOnPageCount: navigationChangeRequestsOnPageKeys.length,
    isCurrentKeyOnPage,
    navigateToFirstChangeRequest,
    navigateToChangeRequest,
  };
};

export default useChangeRequestNavigator;
