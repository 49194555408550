import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { EvaluationFormModel } from "../EvaluationFormModel";
import { get } from "lodash";

const modelName = "Evaluation";
const subModelName = "WildfireApplication";
const formName = "LandscapeFieldEvaluationForm";
const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

// Part 1: 0-5 Feet
const zeroToFiveFeetFields = (
  form: EvaluationFormModel,
  isIEBuilder?: boolean,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("ZeroToFiveFeetPhotosFolder"),
    defaultToUpload: !isIEBuilder,
    errorMessages: get(errors, "zeroToFiveFeetPhotosFolder.FileCount"),
    legacyEvaluationShouldMakeOptional:
      form.legacyEvaluationSource === "Legacy Wildfire" &&
      !form.areLegacyFileUploadersRequired,
  } as WildfireBoxContentExplorerProps,
  buildFieldKey("IsHIZFreeFromVegetation"),
  buildFieldKey("IsGroundCoverIntheHIZNoncombustible"),
  buildFieldKey("IsFencingNearHomeNonCombustible"),
  buildFieldKey("IsHIZFreeFromCombustibleItems"),
  buildFieldKey("IsHIZFreeOfVehicles"),
];
const zeroToFiveFeetRequiredFields = [
  buildFieldKey("ZeroToFiveFeetPhotosFolder"),
  buildFieldKey("IsGroundCoverIntheHIZNoncombustible"),
  buildFieldKey("IsHIZFreeFromVegetation"),
  buildFieldKey("IsHIZFreeFromCombustibleItems"),
  buildFieldKey("IsHIZFreeOfVehicles"),
  buildFieldKey("IsFencingNearHomeNonCombustible"),
];

export const landscapeFieldEvaluationZeroToFiveFeetFieldsBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        zeroToFiveFeetFields(form, false, errors),
        onValueChange,
        form,
        errors,
        zeroToFiveFeetRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};

// Part 2: 5-30 Feet
const fiveToThirtyFeetFields = (
  form: EvaluationFormModel,
  isIEBuilder?: boolean,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("FiveToThirtyFeetPhotosFolder"),
    defaultToUpload: !isIEBuilder,
    errorMessages: get(errors, "fiveToThirtyFeetPhotosFolder.FileCount"),
    legacyEvaluationShouldMakeOptional:
      form.legacyEvaluationSource === "Legacy Wildfire" &&
      !form.areLegacyFileUploadersRequired,
  } as WildfireBoxContentExplorerProps,
  buildFieldKey("IsYardFreeOfDebris"),
  buildFieldKey("HasAllDeadVegetationRemoved"),
  buildFieldKey("TreesSpacedApartAndPruned"),
  buildFieldKey("SmallVegetationSpacingCompliance"),
  buildFieldKey("IsGapBetweenBackToBackFencesSufficient"),
];
const fiveToThirtyFeetRequiredFields = [
  buildFieldKey("FiveToThirtyFeetPhotosFolder"),
  buildFieldKey("IsYardFreeOfDebris"),
  buildFieldKey("TreesSpacedApartAndPruned"),
  buildFieldKey("SmallVegetationSpacingCompliance"),
  buildFieldKey("HasAllDeadVegetationRemoved"),
  buildFieldKey("IsGapBetweenBackToBackFencesSufficient"),
];

export const landscapeFieldEvaluationFiveToThirtyFeetBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        fiveToThirtyFeetFields(form, false, errors),
        onValueChange,
        form,
        errors,
        fiveToThirtyFeetRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};

// Full form builder with all parts included
export const landscapeFieldEvaluationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        [
          ...zeroToFiveFeetFields(form, false, errors),
          ...fiveToThirtyFeetFields(form, false, errors),
        ],
        onValueChange,
        form,
        errors,
        [...zeroToFiveFeetRequiredFields, ...fiveToThirtyFeetRequiredFields],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const wieLandscapeFieldEvaluationFormBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        [
          ...zeroToFiveFeetFields(form, true, errors),
          ...fiveToThirtyFeetFields(form, true, errors),
        ],
        onValueChange,
        form,
        errors,
        [...zeroToFiveFeetRequiredFields, ...fiveToThirtyFeetRequiredFields],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];

    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};
