import { dialogStore } from "@ucl/library";
import "../styles.scss";
import "./styles.scss";
import { GridCommentsComponent } from "./GridCommentsComponent";

const dialogIdBase = "wildfire-grid-comments-dialog";

const getDialogId = (evaluationId: string): string =>
  `${dialogIdBase}_${evaluationId}`;

export const openGridCommentsDialog = (
  evaluationId: string,
  wildfirePreparedId: string,
  refreshGrid?: () => Promise<void>
) =>
  dialogStore.openDialog({
    id: getDialogId(evaluationId),
    className: dialogIdBase,
    header: `Comments: ${wildfirePreparedId}`,
    allowDragging: false,
    content: () => (
      <GridCommentsComponent
        evaluationId={evaluationId}
        refreshGrid={refreshGrid}
        onCancel={() => closeGridCommentsDialog(evaluationId)}
      />
    ),
  });

export const closeGridCommentsDialog = (evaluationId: string) =>
  dialogStore.closeDialog(getDialogId(evaluationId));
