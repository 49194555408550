import {
  ApplicationFormModel,
  ApplicationFormParts,
} from "../../../forms/ApplicationForm/ApplicationFormModel";
import { EvaluatorAssignmentFormModel } from "../../../components/Dialogs/EvaluatorAssignment/EvaluatorAssignmentForm";
import { WildfireEvaluation } from "../../../types/WildfireApplication/WildfireApplication";
import wildfireBaseApiClient from "../../wildfireBaseApiClient";
import {
  RoofFieldEvaluationFormModel,
  RoofFieldEvaluationFormParts,
} from "../../../forms/RoofFieldEvaluationForm/RoofFieldEvaluationFormModel";
import {
  AddressFieldValue,
  Option,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { AdminUpdateFormModel } from "../../../components/Dialogs/AdminUpdate/AdminUpdateForm";
import {
  HomeExteriorFieldEvaluationFormModel,
  HomeExteriorFieldEvaluationFormParts,
} from "../../../forms/HomeExteriorFieldEvaluationForm/HomeExteriorFieldEvaluationFormModel";
import { EvaluationFormModel } from "../../../forms/EvaluationFormModel";
import {
  LandscapeFieldEvaluationFormModel,
  LandscapeFieldEvaluationFormParts,
} from "../../../forms/LandscapeFieldEvaluationForm/LandscapeFieldEvaluationFormModel";
import {
  OtherFieldEvaluationFormModel,
  OtherFieldEvaluationFormParts,
} from "../../../forms/OtherFieldEvaluationForm/OtherFieldEvaluationFormModel";
import { WildfireRejectionFormModel } from "../../../components/Dialogs/Rejection/WildfireRejectionForm";
import { WildfireFieldEvaluationCompliance } from "../../../types/WildfireFieldComplianceDTO";
import { WildfireEvaluationFormModel } from "../../../forms/WildfireEvaluation/WildfireEvaluationFormModel";

class WildfireEvaluationApiClient {
  createWildfireEvaluation = async () => {
    return wildfireBaseApiClient.post<WildfireEvaluation>(
      `wildfire-evaluation`
    );
  };

  getWildfireEvaluationsByOwner = async () => {
    return wildfireBaseApiClient.get<WildfireEvaluation[]>(
      `wildfire-evaluation/owned`
    );
  };

  getWildfireEvaluationById = async (id: string) => {
    return wildfireBaseApiClient.get<WildfireEvaluation>(
      `wildfire-evaluation/${id}`
    );
  };

  getWildfireEvaluationCommentAndChangeRequestFieldKeys = async (
    id: string
  ) => {
    return wildfireBaseApiClient.get<string[]>(
      `wildfire-evaluation/${id}/comment-change-request-field-keys`
    );
  };

  getWildfireEvaluationWithFormsById = async (id: string) => {
    return wildfireBaseApiClient.get<EvaluationFormModel>(
      `wildfire-evaluation/${id}`
    );
  };

  // Workflow
  sendForward = async (id: string) => {
    return wildfireBaseApiClient.post(
      `wildfire-evaluation/${id}/workflow/send-forward`
    );
  };

  requestChanges = async (id: string) => {
    // Sends email only, doesn't change status
    return wildfireBaseApiClient.post(
      `wildfire-evaluation/${id}/workflow/request-changes`
    );
  };

  approve = async (id: string) => {
    return wildfireBaseApiClient.post<WildfireEvaluation>(
      `wildfire-evaluation/${id}/workflow/approve`
    );
  };

  reject = async (
    wildfireApplicationId: string,
    form: WildfireRejectionFormModel
  ) => {
    return wildfireBaseApiClient.post<WildfireEvaluation>(
      `wildfire-evaluation/${wildfireApplicationId}/workflow/reject`,
      form
    );
  };

  // Application Form
  getWildfireEvaluationApplicationForm = async (
    wildfireEvaluationId: string
  ): Promise<EvaluationFormModel> => {
    return wildfireBaseApiClient.get<EvaluationFormModel>(
      `/wildfire/form/${wildfireEvaluationId}/application-form`
    );
  };

  updateApplicationForm = async (
    wildfireApplicationId: string,
    applicationForm: ApplicationFormModel,
    fieldKey?: string
  ): Promise<EvaluationFormModel> => {
    return wildfireBaseApiClient.put<EvaluationFormModel>(
      `/wildfire/form/${wildfireApplicationId}/application`,
      {
        applicationForm,
        fieldKey,
      }
    );
  };

  submitApplicationForm = async (
    wildfireApplicationId: string,
    applicationForm: ApplicationFormModel,
    applicationFormPart?: ApplicationFormParts
  ): Promise<EvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const applicationFormPartValue = (
      applicationFormPart ??
      (Object.values(ApplicationFormParts).pop() as ApplicationFormParts)
    ).valueOf();
    return wildfireBaseApiClient.post<EvaluationFormModel>(
      `/wildfire/form/${wildfireApplicationId}/application/${applicationFormPartValue}`,
      applicationForm
    );
  };

  getFormFieldDecisioning = async (
    wildfireEvalautionId: string
  ): Promise<WildfireFieldEvaluationCompliance> => {
    return wildfireBaseApiClient.get<WildfireFieldEvaluationCompliance>(
      `/wildfire-evaluation/${wildfireEvalautionId}/form-field-decisioning`
    );
  };

  getRoofFieldEvaluationForm = async (
    wildfireEvaluationId: string
  ): Promise<EvaluationFormModel> => {
    return wildfireBaseApiClient.get<EvaluationFormModel>(
      `/wildfire/form/${wildfireEvaluationId}/roof-field-evaluation`
    );
  };

  updateRoofFieldEvaluationForm = async (
    wildfireApplicationId: string,
    roofFieldEvaluationForm: RoofFieldEvaluationFormModel,
    fieldKey?: string
  ): Promise<EvaluationFormModel> => {
    return wildfireBaseApiClient.put<EvaluationFormModel>(
      `/wildfire/form/${wildfireApplicationId}/roof-field-evaluation`,
      {
        roofFieldEvaluationForm,
        fieldKey,
      }
    );
  };

  submitRoofFieldEvaluationForm = async (
    wildfireApplicationId: string,
    roofFieldEvaluationForm: RoofFieldEvaluationFormModel,
    roofFieldEvaluationFormPart?: RoofFieldEvaluationFormParts
  ): Promise<EvaluationFormModel> => {
    // If roofFieldEvaluationFormPart is not provided, default to the last part of the form
    const roofFieldEvaluationFormPartValue = (
      roofFieldEvaluationFormPart ??
      (Object.values(
        RoofFieldEvaluationFormParts
      ).pop() as RoofFieldEvaluationFormParts)
    ).valueOf();
    return wildfireBaseApiClient.post<EvaluationFormModel>(
      `/wildfire/form/${wildfireApplicationId}/roof-field-evaluation/${roofFieldEvaluationFormPartValue}`,
      roofFieldEvaluationForm
    );
  };

  getHomeExteriorFieldEvaluationForm = async (
    wildfireEvaluationId: string
  ): Promise<EvaluationFormModel> => {
    return wildfireBaseApiClient.get<EvaluationFormModel>(
      `/wildfire/form/${wildfireEvaluationId}/home-exterior-field-evaluation`
    );
  };

  updateHomeExteriorFieldEvaluationForm = async (
    wildfireApplicationId: string,
    homeExteriorFieldEvaluationForm: HomeExteriorFieldEvaluationFormModel,
    fieldKey?: string
  ): Promise<EvaluationFormModel> => {
    return wildfireBaseApiClient.put<EvaluationFormModel>(
      `/wildfire/form/${wildfireApplicationId}/home-exterior-field-evaluation`,
      {
        homeExteriorFieldEvaluationForm,
        fieldKey,
      }
    );
  };

  submitHomeExteriorFieldEvaluationForm = async (
    wildfireApplicationId: string,
    homeExteriorFieldEvaluationForm: HomeExteriorFieldEvaluationFormModel,
    homeExteriorFieldEvaluationFormPart?: HomeExteriorFieldEvaluationFormParts
  ): Promise<EvaluationFormModel> => {
    // If homeExteriorFieldEvaluationFormPart is not provided, default to the last part of the form
    const homeExteriorFieldEvaluationFormPartValue = (
      homeExteriorFieldEvaluationFormPart ??
      (Object.values(
        HomeExteriorFieldEvaluationFormParts
      ).pop() as HomeExteriorFieldEvaluationFormParts)
    ).valueOf();
    return wildfireBaseApiClient.post<EvaluationFormModel>(
      `/wildfire/form/${wildfireApplicationId}/home-exterior-field-evaluation/${homeExteriorFieldEvaluationFormPartValue}`,
      homeExteriorFieldEvaluationForm
    );
  };

  getLandscapeFieldEvaluationForm = async (
    wildfireEvaluationId: string
  ): Promise<EvaluationFormModel> => {
    return wildfireBaseApiClient.get<EvaluationFormModel>(
      `/wildfire/form/${wildfireEvaluationId}/landscape-field-evaluation`
    );
  };

  updateLandscapeFieldEvaluationForm = async (
    wildfireApplicationId: string,
    landscapeFieldEvaluationForm: LandscapeFieldEvaluationFormModel,
    fieldKey?: string
  ): Promise<EvaluationFormModel> => {
    return wildfireBaseApiClient.put<EvaluationFormModel>(
      `/wildfire/form/${wildfireApplicationId}/landscape-field-evaluation`,
      {
        landscapeFieldEvaluationForm,
        fieldKey,
      }
    );
  };

  submitLandscapeFieldEvaluationForm = async (
    wildfireApplicationId: string,
    landscapeFieldEvaluationForm: LandscapeFieldEvaluationFormModel,
    landscapeFieldEvaluationFormPart?: LandscapeFieldEvaluationFormParts
  ): Promise<EvaluationFormModel> => {
    // If landscapeFieldEvaluationFormPart is not provided, default to the last part of the form
    const landscapeFieldEvaluationFormPartValue = (
      landscapeFieldEvaluationFormPart ??
      (Object.values(
        LandscapeFieldEvaluationFormParts
      ).pop() as LandscapeFieldEvaluationFormParts)
    ).valueOf();
    return wildfireBaseApiClient.post<EvaluationFormModel>(
      `/wildfire/form/${wildfireApplicationId}/landscape-field-evaluation/${landscapeFieldEvaluationFormPartValue}`,
      landscapeFieldEvaluationForm
    );
  };

  getOtherFieldEvaluationForm = async (
    wildfireEvaluationId: string
  ): Promise<EvaluationFormModel> => {
    return wildfireBaseApiClient.get<EvaluationFormModel>(
      `/wildfire/form/${wildfireEvaluationId}/other-field-evaluation`
    );
  };

  updateOtherFieldEvaluationForm = async (
    wildfireApplicationId: string,
    otherFieldEvaluationForm: OtherFieldEvaluationFormModel,
    fieldKey?: string
  ): Promise<EvaluationFormModel> => {
    return wildfireBaseApiClient.put<EvaluationFormModel>(
      `/wildfire/form/${wildfireApplicationId}/other-field-evaluation`,
      {
        otherFieldEvaluationForm,
        fieldKey,
      }
    );
  };

  submitOtherFieldEvaluationForm = async (
    wildfireApplicationId: string,
    otherFieldEvaluationForm: OtherFieldEvaluationFormModel,
    otherFieldEvaluationFormPart?: OtherFieldEvaluationFormParts
  ): Promise<EvaluationFormModel> => {
    // If roofFieldEvaluationFormPart is not provided, default to the last part of the form
    const otherFieldEvaluationFormPartValue = (
      otherFieldEvaluationFormPart ??
      (Object.values(
        OtherFieldEvaluationFormParts
      ).pop() as OtherFieldEvaluationFormParts)
    ).valueOf();
    return wildfireBaseApiClient.post<EvaluationFormModel>(
      `/wildfire/form/${wildfireApplicationId}/other-field-evaluation/${otherFieldEvaluationFormPartValue}`,
      otherFieldEvaluationForm
    );
  };

  // Admin update
  submitAdminUpdate = async (
    wildfireEvaluationId: string,
    form: AdminUpdateFormModel
  ) => {
    return wildfireBaseApiClient.post(
      `/wildfire-evaluation/${wildfireEvaluationId}/admin-update`,
      form
    );
  };

  // Evaluator assignment
  submitEvaluatorAssignment = async (
    wildfireApplicationId: string,
    form: EvaluatorAssignmentFormModel
  ) => {
    return wildfireBaseApiClient.post(
      `/wildfire-evaluation/${wildfireApplicationId}/assign-evaluation-company`,
      form
    );
  };

  duplicateAddressCheck = async (
    wildfireEvaluationId: string,
    address: AddressFieldValue
  ) => {
    return wildfireBaseApiClient.post<boolean>(
      `/wildfire-evaluation/${wildfireEvaluationId}/duplicate-address-check`,
      address
    );
  };

  downloadCertificate = async (wildfireEvaluationId: string) => {
    return wildfireBaseApiClient.download(
      `/wildfire-evaluation/${wildfireEvaluationId}/get-certificate`
    );
  };

  regenerateCertificate = async (wildfireEvaluationId: string) => {
    return wildfireBaseApiClient.download(
      `/wildfire-evaluation/${wildfireEvaluationId}/regenerate-evaluation-certificate`
    );
  };

  cancelMadronusIntegration = async (wildfireEvaluationId: string) => {
    return wildfireBaseApiClient.post(
      `/wildfire-evaluation/${wildfireEvaluationId}/madronus-integration/cancel`
    );
  };

  getEvaluationCompanies = async (evaluationId: string) => {
    return wildfireBaseApiClient.get<Option<number>[]>(
      `/wildfire-evaluation/${evaluationId}/evaluation-company-zip-codes`
    );
  };

  getRejectionReasonOptionValues = async () => {
    return wildfireBaseApiClient.get<Option<number>[]>(
      `/wildfire-evaluation/rejection-reason-options`
    );
  };
  claimEvaluation = async (evaluationId: string) => {
    return await wildfireBaseApiClient.put(
      `/wildfire-evaluation/${evaluationId}/claim`
    );
  };

  closeEvaluation = async (evaluationId: string) => {
    return await wildfireBaseApiClient.put(
      `/wildfire-evaluation/${evaluationId}/close`
    );
  };

  cancelEvaluation = async (evaluationId: string) => {
    return await wildfireBaseApiClient.put(
      `/wildfire-evaluation/${evaluationId}/cancel`
    );
  };

  reOpenEvaluation = async (evaluationId: string) => {
    return await wildfireBaseApiClient.put(
      `/wildfire-evaluation/${evaluationId}/re-open`
    );
  };

  resubmitEvaluation = async (evaluationId: string) => {
    return await wildfireBaseApiClient.put(
      `/wildfire-evaluation/${evaluationId}/resubmit`
    );
  };

  updateWildfireEvaluationForm = async (
    wildfireApplicationId: string,
    wildfireEvaluationForm: WildfireEvaluationFormModel,
    fieldKey?: string
  ): Promise<EvaluationFormModel> => {
    return wildfireBaseApiClient.put<EvaluationFormModel>(
      `/wildfire/form/${wildfireApplicationId}`,
      {
        applicationForm: wildfireEvaluationForm.applicationForm,
        homeExteriorFieldEvaluationForm:
          wildfireEvaluationForm.homeExteriorFieldEvaluationForm,
        landscapeFieldEvaluationForm:
          wildfireEvaluationForm.landscapeFieldEvaluationForm,
        otherFieldEvaluationForm:
          wildfireEvaluationForm.otherFieldEvaluationForm,
        roofFieldEvaluationForm: wildfireEvaluationForm.roofFieldEvaluationForm,
        fieldKey,
      }
    );
  };
}

export const wildfireEvaluationApiClient = new WildfireEvaluationApiClient();
