import { Observer } from "mobx-react";
import React from "react";
import "../../pages/styles.scss";
import { FortifiedGrid } from "../FortifiedGrid/FortifiedGrid";
import { FortifiedGridViewPicker } from "../FortifiedGridViewsModule/FortifiedGridViewPicker";
import { FortifiedGridFiltersModule } from "../FortifiedGridFiltersModule/FortifiedGridFiltersModule";
import { useFortifiedGridViewModule } from "../../../common/customHooks/useFortifiedGridViewModule";
import { getGridColumnsConfigurationByRole } from "./configurations/gridColumnsConfiguration";
import { dialogsViewerStore } from "../../stores/DialogsViewerStore";
import { fortifiedGridViewModuleStore } from "../../stores/FortifiedGridViewModuleStore";
import variables from "../../../common/config/variables";
import { permissionStore } from "../../stores/PermissionStore";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../../AppInsights";

export interface BoardProps {}

const UserGridComponent: React.FC<BoardProps> = () => {
  // Grid View Module
  useFortifiedGridViewModule(
    "user-board-grid",
    "User Board",
    getGridColumnsConfigurationByRole,
    (_role) => "All"
  );

  return (
    <div>
      <section className="board-toolbar">
        <ul className="board-toolbar-left">
          <li>
            {/* Grid Views Module - Picker */}
            <FortifiedGridViewPicker />
          </li>
        </ul>
        <ul className="board-toolbar-right">
          <li>
            {/* Grid Filters Module */}
            <FortifiedGridFiltersModule />
          </li>
        </ul>
      </section>
      <section className="board-grid-container has-tabs">
        {/* User Admin Grid */}
        <Observer>
          {() => (
            <>
              {!!fortifiedGridViewModuleStore.currentGridView && (
                <FortifiedGrid<{ id: string }>
                  key={fortifiedGridViewModuleStore.currentGridViewKey}
                  dataSource={`${variables.apiBaseUrl}odata/userodata`}
                  columnsConfiguration={[
                    ...fortifiedGridViewModuleStore.currentGridView
                      .columnConfiguration,
                  ]}
                  filterConfiguration={[
                    ...(fortifiedGridViewModuleStore.currentGridView
                      .filterConfiguration || []),
                  ]}
                  sortConfiguration={[
                    ...(fortifiedGridViewModuleStore.currentGridView
                      .sortConfiguration || []),
                  ]}
                  gridConfiguration={{
                    allowTextWrap: true,
                    textWrapSettings: { wrapMode: "Header" },
                    defaultSort: {
                      field: "createdAt",
                      direction: "Descending",
                    },
                    exportFilename: "Users.xlsx",
                    onRecordClick: ({ rowData }) => {
                      if (permissionStore.canEditUsers) {
                        dialogsViewerStore.setIsUserUpdateDialogOpen(true, {
                          userId: rowData.id,
                        });
                      }
                    },
                  }}
                />
              )}
            </>
          )}
        </Observer>
      </section>
    </div>
  );
};

export const UserGrid = withAITracking(
  reactPlugin,
  UserGridComponent,
  "UserGrid",
  "tracking-container"
);
