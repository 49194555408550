import { NonIdealState } from "@blueprintjs/core";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { reactPlugin } from "../../AppInsights";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { PageSidebar } from "../components/PageSidebar/PageSidebar";
import { evaluationStore } from "../stores/EvaluationStore";
import "./styles.scss";
import { EvaluationBidContainer } from "../components/Evaluation/EvaluationBid/EvaluationBidContainer";
import { Header } from "../components/Header/Header";
import { useProductTypeHandler } from "../providers/ProductTypeHandlerProvider";

export interface EvaluationProps {}

const EvaluationBidPageComponent: React.FC<EvaluationProps> = () => {
  const { id } = useParams<{ id: string }>();

  const { routePrefix } = useProductTypeHandler();

  if (!id) {
    return <NonIdealState />;
  }

  useEffect(() => {
    evaluationStore.init(id);

    return () => evaluationStore.reset();
  }, []);

  return (
    <div className="bids-page">
      <Observer>
        {() => (
          <>
            <Header
              breadCrumbs={[
                { href: `${routePrefix}`, text: "Board" },
                {
                  text: evaluationStore.evaluationBreadcrumbText,
                },
              ]}
            />
            {!!evaluationStore.evaluation ? (
              <div className="bids-content">
                {<PageSidebar activePage="home" />}

                <EvaluationBidContainer
                  evaluationId={evaluationStore.evaluation.id}
                />
              </div>
            ) : (
              <Loading />
            )}
          </>
        )}
      </Observer>
    </div>
  );
};

export const EvaluationBidPage = withAITracking(
  reactPlugin,
  EvaluationBidPageComponent,
  "EvaluationBidPage",
  "tracking-container"
);
