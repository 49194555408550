import { RulesLogic } from "json-logic-js";
import { ExistingResource } from "./ExistingResource";
import { EvaluationBid } from "./EvaluationBid";

export enum EvaluationStep {
  Application = 1,
  EligibilityReview = 2,
  EvaluatorAssignment = 3,
  ComplianceFormSubmitted = 4,
  FieldInspectionsSubmitted = 5,
  EvaluatorComplianceLetterSubmitted = 6,
  FinalReview = 7,
  ApplicationForms = 8,
  Payment = 9,
  ApplicationFee = 10,
  CertificateFee = 11,
}

export enum TraineeAuditStatuses {
  IBHSReview = 1,
  ReviewComplete = 2,
  ChangesRequired = 3,
  ReadyForReview = 4,
}

export enum WildfireEvaluationSteps {
  WildfireApplication = 300,
  WildfireApplicationPayment = 301,
  WildfireApplicationIBHSEligibilityReview = 302,
  WildfireApplicationEvaluatorAssignment = 303,
  WildfireApplicationFieldEvaluations = 304,
  WildfireApplicationIBHSReview = 305,
  WildfireApplicationEligibilityPhotos = 306,
}

export enum AdminEvaluationSteps {
  EligibilityReviewed = 1,
  ApplicationForms = 2,
  Payment = 3,
  IBHSReview = 4,
  Approved = 5,
  Rejected = 6,
}

//Enum is converted to its string representation in the grid. Not EnumMember, so we need to use it as a string
export enum EvaluationStep_AsString {
  Application = "ApplicationInitiated",
  EligibilityReview = "EligibilityReviewed",
  EvaluatorAssigned = "EvaluatorAssignment",
  ComplianceFormSubmitted = "ComplianceFormSubmitted",
  FieldInspectionsSubmitted = "FieldInspectionsSubmitted",
  EvaluatorComplianceLetterSubmitted = "EvaluatorComplianceLetterSubmitted",
  FinalReview = "IBHSReview",
  ApplicationForms = "ApplicationForms",
  Payment = "Payment",
}

//Enum is converted to its string representation in the grid. Not EnumMember, so we need to use it as a string
export enum ProductTypes_AsString {
  None = "None",
  FortifiedCommercial = "FortifiedCommercial",
  FortifiedMultifamily = "FortifiedMultifamily",
  FortifiedHome = "FortifiedHome",
}

export enum ProductTypes {
  FortifiedCommercial = 1,
  FortifiedMultifamily = 2,
  FortifiedHome = 3,
}

export enum ApprovalTypes {
  Undetermined = 0,
  Certificate = 1,
  ComplianceLetter = 2,
}

export interface NewEvaluationField {
  evaluationFieldName: string;
  description: string;
  fieldType: string;
  id: string;
  isHidden: boolean;
  label: string;
  optionValues: {
    label: string;
    value: string;
  }[];
  sortOrder: number;
  updatedAt: string;
  updatedBy: string;
  value: string;
  externalId: string;
  extData: string;
  rule?: RulesLogic;
  canBeUpdated: boolean;
}
export type EvaluationField = ExistingResource<NewEvaluationField>;

export interface NewEvaluationForm {
  sortOrder: number;
  evaluationItemType: number;
  evaluationItemTypes_AsString: string;
  evaluationFields: EvaluationField[];
  isCompleted: boolean;
  externalUrl: string;
  isDisabled: boolean;
  isSyncing?: boolean;
  evaluationItemDependencies: number[];
  evaluationItemUrlParameters: { [key: string]: string[] };
  evaluationItemCompleterRoles: number[];
}
export type EvaluationForm = ExistingResource<NewEvaluationForm>;

export interface EvaluationChild {
  id: string;
  fortifiedId: string;
  parentEvaluationId: string;
  originEvaluationId: string;
  productType: number;
  productType_AsString: string;
  step: EvaluationStep;
  status_AsString: string;
  isApproved?: boolean;
  approvedAt?: Date;
  approvedAt_AsDateOnly?: Date;
  expiresAt?: Date;
  expiresAt_AsDateOnly?: Date;
  isEvaluationFullyExpired: boolean;
  isEvaluationEarly: boolean;
  isParentEvaluation: boolean;

  evaluationExpirationDateType: number;
  evaluationExpirationDateType_AsString: string;
}

export interface EvaluationRedesignationHistory {
  id: string;
  fortifiedId: string;
  parentEvaluationId: string;
  originEvaluationId: string;
  productType: number;
  productType_AsString: string;
  step: EvaluationStep;
  status_AsString: string;
  isApproved?: boolean;
  approvedAt?: Date;
  expiresAt?: Date;
  isRedesignation: boolean;
}

export interface ComplexCommercialEvaluationFamilyBuilding {
  id: string;
  fortifiedId: string;
}

export interface ComplexCommercialEvaluationBuildingRelationship {
  id: string;
  fortifiedId: string;
  createdAt: Date;
  buildings: ComplexCommercialEvaluationFamilyBuilding[];
}

export interface ComplexCommercialEvaluationBuildingValidationDTO {
  areAllRequiredBuildingFieldsFieldsFilledOut: boolean;
  buildingValidationErrors: string[];
}

export interface ExternalEvaluationBidSummary {
  id: string;
  fortifiedId: string;
  parentEvaluationId: string;
  isRedesignation: boolean;
  productType: number;
  productType_AsString: string;
  step: EvaluationStep;
  status_AsString: string;
  buildingName: string;
  buildingAddress_AsString: string;
  externalFolderId: string;
  evaluationForms: EvaluationForm[];
  evaluationBids: EvaluationBid[];
}

export interface NewComplexCommercialEvaluationBuilding {
  id: string;
  fortifiedId: string;
  productType: number;
  productType_AsString: string;
  step: EvaluationStep;
  status_AsString: string;
  isBlockingParentFromApproval: boolean;
  commercialApplicationForm: EvaluationForm;

  buildingName: string;
  buildingAddress: string;
  buildingAddress2: string;
  buildingCity: string;
  buildingState: string;
  buildingZip: string;
  isNewConstruction: boolean;
  buildingState_AsString: string;
  numberOfUnits?: number;

  multifamilyPrimaryUse: string;
  projectPhase: string;

  sizeSqfts: number;
  heightFts: number;
  approxSizeSqft: number;
  approxHeightFt: number;
  areAllRequiredFieldsFilledOut: boolean;
}

export interface NewEvaluation {
  id: string;
  fortifiedId: string;
  parentEvaluationId: string;
  complexCommercialAndMultifamilyEvaluationId: string;
  isRedesignation: boolean;
  productType: number;
  productType_AsString: string;
  step: EvaluationStep;
  approvalType: ApprovalTypes;
  status_AsString: string;
  statusUpdatedAt: Date;
  isApproved?: boolean;
  approvedAt?: Date;
  expiresAt?: Date;
  submittedDate?: Date;
  previousEvaluationExpirationDateType: number;
  previousEvaluationExpirationDateType_AsString: string;
  homeOwnerFirstName: string;
  homeOwnerLastName: string;
  homeOwnerEmail: string;
  homeOwnerPhone: string;

  isWithdrawn?: boolean;
  canWithdraw?: boolean;
  withdrawnDate?: Date;
  withdrawnBy: string;
  withdrawnBy_AsName: string;
  withdrawnReason: string;

  reactivateDate?: Date;
  isEvaluationClaimed?: boolean;
  legacyEvaluationSource?: string;
  isLegacyEvaluation?: boolean;
  evaluationCompany: string;
  evaluationForms: EvaluationForm[];
  evaluationItemDataIdsWithInternalComments: string[];
  businessName: string;
  businessAddress: string;
  businessCity: string;
  businessState: string;
  businessState_AsString: string;
  businessZip: string;
  businessPhoneNumber: string;
  businessEmail: string;
  isApplicantBuildingOwner: boolean;
  buildingName: string;
  buildingAddress: string;
  buildingAddress2: string;
  buildingCity: string;
  buildingState: string;
  buildingState_AsString: string;
  buildingZip: string;
  buildingCounty: string;
  isNewConstruction: boolean;
  designationLevel: number;
  designationLevel_AsString: string;
  hazard: number;
  hazard_AsString: string;
  hasHailSupplement: boolean;
  externalFolderId: string;
  postDesignationBoxFolderId: string;
  evaluatorId: string;
  auditorId: string;
  auditorTraineeId: string;
  traineeAuditStatus: TraineeAuditStatuses;
  unreadCommentsCount: string;
  hasSufficientPaymentCredits: boolean;
  paymentLink?: string;
  numberOfUnits?: number;
  isNonProfitProject?: boolean;
  otherEvaluationsHaveSameAddress: boolean;
  fortifiedRejectionReasons: string[];
  rejectionReasons_AsCSV: string;
  rejectionAdditionalNotes: string;

  //Commercial Fields
  applicantFirstName: string;
  applicantLastName: string;
  applicantTitle: string;
  numberOfRoofCoverSystems: number;
  multifamilyPrimaryUse: string;
  grossRoofAreaSqft: number;
  totalFloorAreaSqft: number;
  projectPhase: string;
  ibcVersion: string;
  asceCode: string;
  importanceFactor: string;
  exposureCategory: string;

  yearBuilt: string;
  occupiedYears: string;

  companyName: string;
  companyPhone: string;
  companyEmail: string;
  sizeSqfts: number;
  heightFts: number;
  approxSizeSqft: number;
  approxHeightFt: number;
  latitude: string;
  longitude: string;
  multiUnitAppId: string;

  hasPaymentBeenSubmitted: boolean;
  isPaymentApplicable: boolean;
  isAutoRejected?: boolean;
  wasResubmitted?: boolean;
  isSpecialProject?: boolean;
  shouldShowAutoRejectBanner: boolean;

  hasAnEvaluationBidBeenAccepted: boolean;
  isComplexCommercialEvaluation: boolean;
  isComplexCommercialEvaluationBuilding: boolean;
  isComplexCommercialEvaluationBuildingOrSingleBuilding: boolean;

  hasFinalCertificateFeePaymentBeenProcessed: boolean;
  isEvaluationCreatorExemptFromPayment: boolean;
  hasFCFMFPaymentWorkflow: boolean;
  areBuildingsFinishedBeingProcessed: boolean;
}
export type Evaluation = ExistingResource<NewEvaluation>;

export type ComplexCommercialEvaluationBuilding =
  ExistingResource<NewComplexCommercialEvaluationBuilding>;
