import { useContext } from "react";
import CommentListViewV2 from "./IterationEngineCommentList";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import "./styles.scss";
import IterationEngineCommentInput from "./IterationEngineCommentInput";
import { EvaluationCommentV2 } from "../types/EvaluationCommentV2";
import { ChangeRequestV2 } from "../types/ChangeRequestV2";
import { EvaluationChangeRequestContext } from "../../Provider/EvaluationChangeRequestProvider";
import useChangeRequestNavigator from "./ChangeRequestNavigator/useChangeRequestNavigator";
import ChangeRequestNavigator from "./ChangeRequestNavigator/ChangeRequestNavigator";
import { PermissionsContext, PermissionsContextProps } from "@ucl/library";
import {
  changeRequestStatusMapToIcon,
  changeRequestStatusMapToIntent,
} from "../../../../foritfied/types/viewModels/IterationEngineChangeRequest";
import { Icon, Tag } from "@blueprintjs/core";

export interface CommentContainerProps {
  comments: EvaluationCommentV2[];
  deleteComment?: (
    commentId: string,
    evaluationFieldKey: string
  ) => Promise<void>;
  createComment?: (
    comment: string,
    evaluationFieldKey: string,
    evaluationFieldLabel: string
  ) => Promise<void>;
  selectedField?: BaseFieldProps<any>;
  changeRequests: ChangeRequestV2[];
  setSelectedKeyAndGetComments:
    | ((fieldKey?: string) => Promise<void>)
    | undefined;
  selectedKey: string | undefined;
  setSelectedIterationEngineFormTabId: (tabId: string) => void;
  selectedIterationEngineFormTabId: string;
  unresolvedChangeRequests: ChangeRequestV2[];
  isOpen: boolean;
  fieldsContainerClassName: string;
}

const IterationEngineCommentPanel: React.FC<CommentContainerProps> = (
  props
) => {
  const {
    allChangeRequests,
    changeRequestCount,
    resolvedChangeRequestCount,
    resolvedChangeRequestsPercentage,
    unresolveChangeRequest,
    resolveChangeRequest,
    getChangeRequests,
  } = useContext(EvaluationChangeRequestContext);

  const {
    moveToNextChangeRequest,
    hasAssociatedChangeRequests,
    hasNoNewChangeRequests,
    navigateToPreviousChangeRequest,
    currentChangeRequestIndex,
    navigateToNextChangeRequest,
    applicantChangeRequestStatusLabel,
    currentChangeRequest,
    fieldsOnPageCount,
    isCurrentKeyOnPage,
  } = useChangeRequestNavigator(
    props,
    allChangeRequests,
    "evaluation-forms-container"
  );

  const { hasPermission } = useContext(
    PermissionsContext
  ) as PermissionsContextProps<PermissionsKey>;

  return (
    <>
      <div className="comment-pannel-v2">
        <div className="comment-container-v2">
          {props.selectedField && fieldsOnPageCount <= 0 && (
            <div className="comment-list-view_header_name">
              {isCurrentKeyOnPage ? props.selectedField?.label : ""}
            </div>
          )}
          {(props.selectedField || fieldsOnPageCount > 0) && (
            <>
              {fieldsOnPageCount > 0 && (
                <>
                  <ChangeRequestNavigator
                    selectedField={props.selectedField}
                    hasAssociatedChangeRequests={hasAssociatedChangeRequests}
                    hasNoNewChangeRequests={hasNoNewChangeRequests}
                    changeRequestCount={changeRequestCount}
                    resolvedChangeRequestCount={resolvedChangeRequestCount}
                    resolvedChangeRequestsPercentage={
                      resolvedChangeRequestsPercentage
                    }
                    currentChangeRequestIndex={currentChangeRequestIndex}
                    navigateToPreviousChangeRequest={
                      navigateToPreviousChangeRequest
                    }
                    navigateToNextChangeRequest={navigateToNextChangeRequest}
                    applicantChangeRequestStatusLabel={
                      applicantChangeRequestStatusLabel
                    }
                  />
                </>
              )}
              {(props.selectedField || currentChangeRequest) && (
                <div className="comment-list-view_header">
                  <div className="comment-list-view_header_name">
                    {props.selectedField?.label ||
                      currentChangeRequest?.evaluationFieldLabel}
                  </div>

                  <div className="comment-list-view_header_indicator">
                    {hasAssociatedChangeRequests &&
                      currentChangeRequest?.status &&
                      applicantChangeRequestStatusLabel && (
                        <Tag
                          round={true}
                          intent={
                            changeRequestStatusMapToIntent[
                              applicantChangeRequestStatusLabel
                            ]
                          }
                        >
                          <Icon
                            icon={
                              changeRequestStatusMapToIcon[
                                applicantChangeRequestStatusLabel
                              ]
                            }
                          />
                          {applicantChangeRequestStatusLabel}
                        </Tag>
                      )}
                  </div>
                </div>
              )}
              {currentChangeRequest && (
                <CommentListViewV2
                  currentKey={currentChangeRequest?.evaluationFieldKey}
                  comments={props.comments}
                  changeRequest={currentChangeRequest}
                  deleteComment={props.deleteComment}
                  hasAssociatedChangeRequests={hasAssociatedChangeRequests}
                  canViewSystemGeneratedComments={hasPermission(
                    "CanViewSystemCommentInCommentsPanel"
                  )}
                />
              )}
              {(props.selectedField || currentChangeRequest) && (
                <IterationEngineCommentInput
                  currentKey={
                    props.selectedField?.fieldKey ||
                    currentChangeRequest?.evaluationFieldKey
                  }
                  changeRequest={currentChangeRequest}
                  hasAssociatedChangeRequests={hasAssociatedChangeRequests}
                  canResolveChangeRequests={hasPermission(
                    "CanResolveChangeRequests"
                  )}
                  onSubmitComment={async (
                    comment,
                    evaluationFieldKey,
                    evaluationFieldLabel
                  ) => {
                    await props.createComment?.(
                      comment,
                      evaluationFieldKey,
                      evaluationFieldLabel
                    );

                    if (!currentChangeRequest) {
                      await getChangeRequests?.();
                    }
                  }}
                  resolveChangeRequest={resolveChangeRequest}
                  onUnresolveChangeRequest={unresolveChangeRequest}
                  selectedField={props.selectedField}
                  moveToNextChangeRequest={moveToNextChangeRequest}
                  getChangeRequests={getChangeRequests}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default IterationEngineCommentPanel;
