import { UserRoleType } from "../../../common/Components/User/userTypes";

export type BoardGridView =
  | "All"
  | "All Active"
  | "Approved"
  | "Not Approved"
  | "Audit Queue"
  | "Auditor Trainee Queue"
  | "Basic Roof"
  | "Basic Gold";

export const boardRoleDefaultView: Record<
  UserRoleType | "Default",
  BoardGridView
> = {
  "FC/FMF Applicant": "All",
  "FC/FMF Evaluator": "All",
  "FC/FMF Auditor": "All", // Audit Queue?
  "FH Applicant": "All",
  "FH Evaluator": "All",
  "FH Auditor": "All", // Audit Queue?
  "FH Auditor Trainee": "Basic Roof",
  "FH Roofer": "All",
  "FH Direct Submit Roofer": "All",
  "FH Engineer": "All",
  "FH Contractor": "All",
  "IBHS Admin": "All",
  "IBHS Partner": "All",
  "FH Evaluator Admin": "All",
  "Relativity Admin": "All",
  "NCIUA Partner": "All",
  "FC/FMF Admin": "All",
  "WFP Applicant": "All",
  "WFP Admin": "All",
  "WFP Evaluator": "All",
  Default: "All",
};

export const getGridDefaultViewByRole = (
  role?: UserRoleType
): BoardGridView => {
  if (!role) {
    return boardRoleDefaultView.Default;
  }

  const boardDefaultViewForRole = boardRoleDefaultView[role];
  return boardDefaultViewForRole
    ? boardDefaultViewForRole
    : boardRoleDefaultView.Default;
};
