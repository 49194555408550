import {
  Classes,
  Dialog,
  FormGroup,
  Icon,
  Intent,
  Label,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import { MultiSelectField } from "../Fields/SelectField/MultiSelectField";
import { DialogFooter } from "./DialogFooter";
import { BaseDialogProps } from "./types";
import { TextAreaField } from "../Fields/TextAreaField/TextAreaField";
import { Option } from "@ucl/library/lib/components/Fields/types/fieldTypes";

const rejectionOptions: Option<number>[] = [
  {
    value: 1,
    label: "Too many change requests",
  },
  {
    value: 2,
    label: "Poor quality images",
  },
  {
    value: 3,
    label: "Documentation deficiencies",
  },
  {
    value: 4,
    label: "Installation does not meet FORTIFIED requirements",
  },
  {
    value: 5,
    label: "Excessive omissions/errors",
  },
  {
    value: 6,
    label: "Roof - Roof deck thickness and/or attachment",
  },
  {
    value: 7,
    label: "Roof - Sealed Roof Deck",
  },
  {
    value: 8,
    label: "Roof - Edge Details",
  },
  {
    value: 9,
    label: "Roof - Roof Cover",
  },
  {
    value: 10,
    label: "Roof Hurricane Only - Attic Vents",
  },
  {
    value: 11,
    label: "Roof - Roof Mounted PV",
  },
  {
    value: 12,
    label: "Roof Compliance Form(s)",
  },
  {
    value: 13,
    label: "Hail - Roof Cover Hail Impact Rating",
  },
  {
    value: 14,
    label: "Hail - Skylights Hail Impact Rating",
  },
  {
    value: 15,
    label: "Hail - Roof Mounted PV Hail Impact Rating",
  },
  {
    value: 16,
    label: "Silver Hurricane Only - Impact Rated Windows and/or Doors",
  },
  {
    value: 17,
    label: "Silver Hurricane Only - Impact Protection Systems (Shutters)",
  },
  {
    value: 18,
    label: "Silver - Garage Door",
  },
  {
    value: 19,
    label: "Silver - Chimney Anchorage",
  },
  {
    value: 20,
    label: "Silver Hurricane Only - Soffits",
  },
  {
    value: 21,
    label: "Silver - Attached Structure Anchorage",
  },
  {
    value: 22,
    label: "Silver - Gable End Wall Sheathing, Framing and/or Bracing",
  },
  {
    value: 23,
    label:
      "Silver Hurricane Only - Gable End Overhang Framing and/or Anchorage",
  },
  {
    value: 24,
    label: "Silver Engineering and/or Installation Compliance Forms",
  },
  {
    value: 25,
    label: "Gold Hurricane Only - Design Pressure Rated Windows and/or Doors",
  },
  {
    value: 26,
    label: "Gold Hurricane Only - Exterior Sheathing",
  },
  {
    value: 27,
    label: "Gold - Continuous Load Path Connections",
  },
  {
    value: 28,
    label: "Gold Engineering and/or Installation Compliance Forms",
  },
  {
    value: 29,
    label: "Other",
  },
  {
    value: 31,
    label:
      "Foundation - Dry stack foundation does not meet FORTIFIED requirements",
  },
];

export interface AuditorRejectionDialogProps
  extends Omit<BaseDialogProps, "submitDialog"> {
  submitDialog: (
    reasons: Option<number>[],
    additionalNotes: string
  ) => Promise<void>;
}

export const AuditorRejectionDialog: React.FC<AuditorRejectionDialogProps> = ({
  isOpen,
  closeDialog,
  submitDialog,
}) => {
  const [reason, setReason] = React.useState<Option<number>[]>([]);
  const [localAdditionalNotes, setLocalAdditionalNotes] =
    React.useState<string>("");

  return (
    <Dialog isOpen={isOpen} hasBackdrop={false} isCloseButtonShown={true}>
      <div className={Classes.DIALOG_HEADER}>
        <Icon intent={Intent.DANGER} icon={IconNames.DELETE} />
        Reject Confirmation
      </div>
      <div className={Classes.DIALOG_BODY}>
        <FormGroup className="multi-select-field">
          <Label>
            Select the items that did not meet FORTIFIED requirements
          </Label>
          <MultiSelectField
            value={reason}
            optionValues={rejectionOptions}
            onSubmit={(value) => {
              const convertedValue = value as Option<number>[];

              setReason(convertedValue);
            }}
            type={"Multiselect"}
          />
          <TextAreaField
            label={"Additional Notes"}
            type={"Textarea"}
            textAreaClassName="comment-textarea"
            value={localAdditionalNotes}
            fieldId={""}
            isSelectedField={false}
            hasActiveChangeRequest={false}
            isSelectedChangeRequest={false}
            isResovledChangeRequest={false}
            hasActiveInternalComment={false}
            isEmpty={() => false}
            onSubmit={(value) => {
              const convertedValue = value as string;
              setLocalAdditionalNotes(convertedValue);
            }}
            fieldType={"Textarea"}
          />
        </FormGroup>
      </div>
      <DialogFooter
        closeDialog={closeDialog}
        isConfirmDisabled={!reason}
        submitDialog={async () => {
          if (!reason) return;

          await submitDialog(reason, localAdditionalNotes);
        }}
      />
    </Dialog>
  );
};
