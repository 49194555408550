import React, { useContext } from "react";
import { Navigate, useNavigate, useOutlet } from "react-router";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import wildfireLogo from "../../assets/wildfire_logo.png";
import "../styles.scss";
import {
  AppHeader,
  AppSideBar,
  PrimaryCard,
  NavigationExpandModes,
  SidebarTypes,
  PermissionsContextProps,
  usePermissions,
  Dialogs,
} from "@ucl/library";

import variables from "../../../common/config/variables";
import { uclRouteObject, wildfireRoutePrefix } from "./wildfireRouteConfig";
import { logOutWildfireUser } from "../../../common/lib/msal/wildfireMsal";
import { wildfireUserStore } from "../../stores/WildfireUserStore";
import { UserAvatar } from "../../../common/Components/Avatar/UserAvatar";
import { useWildfireFeatureToggle } from "../../customHooks/useWildfireFeatureToggle";
import { AppHeaderContext } from "../../components/Provider/AppHeaderContext";
export interface PagesLayoutComponentProps {
  redirectPath: string;
}

const WildfirePagesLayoutComponent: React.FC<PagesLayoutComponentProps> = (
  props
) => {
  const outlet = useOutlet();
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);
  const { isWildfireEnabled } = useWildfireFeatureToggle();

  const { appHeader } = useContext(AppHeaderContext);

  const navigate = useNavigate();

  const { hasPermission } =
    usePermissions() as PermissionsContextProps<PermissionsKey>;

  const ErrorFallback = (args: FallbackProps) => {
    return (
      <PrimaryCard headerContent="Something went wrong">
        <div role="alert">
          <pre>{args.error.message}</pre>
          <button onClick={args.resetErrorBoundary}>Try again</button>
        </div>
      </PrimaryCard>
    );
  };

  return (
    <>
      {isWildfireEnabled && wildfireUserStore.isWildfireUser && (
        <div className="wildfire-page">
          <Dialogs />
          <AppHeader
            theme={variables.theme}
            appLogo={
              <a onClick={() => navigate(`${wildfireRoutePrefix}/board`)}>
                <img src={wildfireLogo} width={100} />
              </a>
            }
            appTitle={appHeader}
            breadcrumbSettings={{
              uclRouteObject: uclRouteObject(hasPermission),
            }}
          >
            <UserAvatar
              logout={logOutWildfireUser}
              userFullName={wildfireUserStore.fullUserName}
              userDisplayRole={wildfireUserStore.displayRole}
            />
          </AppHeader>
          <div className="content">
            {(hasPermission("CanViewAdminPanel") ||
              hasPermission("CanViewReportsPanel")) && (
              <AppSideBar
                uclRouteObject={uclRouteObject(hasPermission)}
                isOpen={false}
                onNavigationExpandChange={(sideBarOpen: boolean) => {
                  if (sideBarOpen !== isSidebarOpen)
                    setIsSidebarOpen(sideBarOpen);
                }}
                showSearchBar={false}
                navigationExpandMode={NavigationExpandModes.AllClosed}
                dockSidebarType={SidebarTypes.Column}
              />
            )}
            <div className="wildfire-page-main-content">
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <div>
                  {outlet || <Navigate to={props.redirectPath} replace />}
                </div>
              </ErrorBoundary>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const WildfirePagesLayout = WildfirePagesLayoutComponent;
export default WildfirePagesLayout;
