import { PermissionsContext, PermissionsContextProps } from "@ucl/library";
import { useContext } from "react";
import { WildfireEvaluationSteps } from "../../foritfied/types/evaluation/Evaluation";

const useWildfirePermissons = (step: WildfireEvaluationSteps | undefined) => {
  const { hasPermission } = useContext(
    PermissionsContext
  ) as PermissionsContextProps<PermissionsKey>;

  const canEditFormInApplicationStep =
    hasPermission("CanEditFormsInApplicationStep") &&
    step === WildfireEvaluationSteps.WildfireApplication;

  const canEditFormInApplicationFeeStep =
    hasPermission("CanEditFormsInApplicationFeeStep") &&
    step === WildfireEvaluationSteps.WildfireApplicationPayment;

  const canEditInPhotoRequirementsStep =
    step === WildfireEvaluationSteps.WildfireApplicationEligibilityPhotos &&
    hasPermission("CanEditFormsInPhotoRequirementsStep");

  const canEditFormInIBHSEligibilityStep =
    hasPermission("CanEditFormsInIBHSEligibilityReviewStep") &&
    step === WildfireEvaluationSteps.WildfireApplicationIBHSEligibilityReview;

  const canEditFormInEvaluatorAssignmentStep =
    hasPermission("CanEditFormsInEvaluatorAssignmentStep") &&
    step === WildfireEvaluationSteps.WildfireApplicationEvaluatorAssignment;

  const canEditFormInFieldEvaluationsStep =
    hasPermission("CanEditFormsInFieldEvaluationsStep") &&
    step === WildfireEvaluationSteps.WildfireApplicationFieldEvaluations;

  const canEditFormInIBHSReviewStep =
    hasPermission("CanEditFormsInIBHSReviewStep") &&
    step === WildfireEvaluationSteps.WildfireApplicationIBHSReview;

  const canEditForm =
    canEditFormInApplicationStep ||
    canEditFormInApplicationFeeStep ||
    canEditFormInIBHSEligibilityStep ||
    canEditFormInEvaluatorAssignmentStep ||
    canEditFormInFieldEvaluationsStep ||
    canEditInPhotoRequirementsStep ||
    canEditFormInIBHSReviewStep;

  return { canEditForm };
};

export default useWildfirePermissons;
