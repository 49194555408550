import {
  EnumSingleSelectFieldProps,
  FieldFormV2,
  FieldFormV2Module,
  FieldSchemaFormBuilderV2,
  Footer,
  SingleCheckboxFieldProps,
  TextFieldProps,
} from "@ucl/library";
import { FC, useRef } from "react";
import { Intent } from "@blueprintjs/core";
import { AppToaster } from "../../../../foritfied/components/Toast/Toast";
import { closeUpdateUserDialog } from "./UpdateUserDialog";
import { wildfireUserApiClient } from "../../../lib/user/wildfireUserApiClient";
import { EvaluationCompanies, WildfireRoles } from "../../../types/FormFields";
import { enumToOptions } from "../../../../common/utils/enum";
import { get } from "lodash";
import WildfireSecondaryButton from "../../Button/DialogFooterButtons/SecondaryButton/SecondaryButton";
import WildfireSubmitButton from "../../Button/DialogFooterButtons/SubmitButton/SubmitButton";

export interface UpdateUserFormProps {
  userId: string;
  role: string;
  companyName: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  afterUpdate?: () => void;
}

export interface UpdateUserFormModel {
  role: WildfireRoles;
  evaluationCompany: EvaluationCompanies; // Only for Evaluators
  firstName: string;
  lastName: string;
  isActive: boolean;
}

const getRoleOptionLabel = (role: WildfireRoles): string => {
  switch (role) {
    case WildfireRoles.WildfireApplicant:
      return "Applicant";
    case WildfireRoles.WildfireAdmin:
      return "Admin";
    case WildfireRoles.WildfireEvaluator:
      return "Evaluator";
  }
};

const roleOptionValues = enumToOptions(WildfireRoles, getRoleOptionLabel);
const evaluationCompanyOptionValues = enumToOptions(EvaluationCompanies);

const updateUserFormDefaults = (
  props: UpdateUserFormProps
): UpdateUserFormModel => {
  return {
    role: roleOptionValues.find((item) => item.key === props.role)
      ?.value as WildfireRoles,
    evaluationCompany: evaluationCompanyOptionValues.find(
      (item) => item.key === props.companyName
    )?.value as EvaluationCompanies,
    firstName: props.firstName,
    lastName: props.lastName,
    isActive: props.isActive,
  };
};

const updateUserFormBuilder: FieldSchemaFormBuilderV2<UpdateUserFormModel> = (
  onValueChange,
  form,
  errors
) => {
  return [
    {
      type: "EnumSingleSelect",
      fieldName: "roleSelection",
      fieldKey: "roleSelection",
      label: "Role",
      placeholder: "Please select an option",
      noResultsLabel: "Role not found",
      optionValues: roleOptionValues,
      value: form.role,
      errorMessages: get(errors, "role"),
      onSubmit: function (value) {
        onValueChange({ ...form, role: value as WildfireRoles }, value, this);
      },
    } as EnumSingleSelectFieldProps,
    {
      type: "EnumSingleSelect",
      fieldName: "evaluationCompanySelection",
      fieldKey: "evaluationCompanySelection",
      label: "Evaluation Company",
      placeholder: "Please select an option",
      noResultsLabel: "Item not found",
      value: form.evaluationCompany,
      optionValues: evaluationCompanyOptionValues,
      hidden: form.role !== WildfireRoles.WildfireEvaluator,
      isRequired: form.role === WildfireRoles.WildfireEvaluator,
      errorMessages: get(errors, "companyName"),
      onSubmit: function (value) {
        onValueChange(
          { ...form, evaluationCompany: value as EvaluationCompanies },
          value,
          this
        );
      },
    } as EnumSingleSelectFieldProps,
    {
      type: "Text",
      fieldName: "firstName",
      fieldKey: "firstName",
      label: "First Name",
      value: form.firstName,
      errorMessages: get(errors, "firstName"),
      onSubmit: function (value) {
        onValueChange({ ...form, firstName: value as string }, value, this);
      },
    } as TextFieldProps,
    {
      type: "Text",
      fieldName: "lastName",
      fieldKey: "lastName",
      label: "Last Name",
      value: form.lastName,
      errorMessages: get(errors, "lastName"),
      onSubmit: function (value) {
        onValueChange({ ...form, lastName: value as string }, value, this);
      },
    } as TextFieldProps,
    {
      type: "SingleCheckbox",
      fieldName: "isActive",
      fieldKey: "isActive",
      label: "Active",
      value: form.isActive,
      errorMessages: get(errors, "isActive"),
      onSubmit: function (value) {
        onValueChange({ ...form, isActive: value as boolean }, value, this);
      },
    } as SingleCheckboxFieldProps,
  ];
};

export const UpdateUserForm: FC<UpdateUserFormProps> = (
  props: UpdateUserFormProps
) => {
  const updateUserFormRef =
    useRef<FieldFormV2Module<UpdateUserFormModel>>(null);

  return (
    <>
      <FieldFormV2<UpdateUserFormModel>
        ref={updateUserFormRef}
        value={updateUserFormDefaults(props)}
        fieldSchemaFormBuilder={updateUserFormBuilder}
        onFormSubmit={async (form) => {
          await wildfireUserApiClient
            .updateUserById(props.userId, {
              ...form,
              companyName: EvaluationCompanies[form.evaluationCompany], // Convert to string
            })
            .then(async () => {
              AppToaster.show({
                message: "Successfully updated user",
                intent: Intent.SUCCESS,
              });
              props.afterUpdate && props.afterUpdate();
              closeUpdateUserDialog(props.userId);
            })
            .catch((error) => {
              if (error.response.status !== 400) {
                console.error(error);
                AppToaster.show({
                  message: "Unexpected error occurred while updating user",
                  intent: Intent.DANGER,
                });
              } else {
                throw error;
              }
            });
        }}
      />
      <Footer>
        <WildfireSecondaryButton
          buttonText="Cancel"
          onClick={() => closeUpdateUserDialog(props.userId)}
        />
        <WildfireSubmitButton
          buttonText="Save"
          onClick={() => updateUserFormRef.current?.submit()}
        />
      </Footer>
    </>
  );
};
