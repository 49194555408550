import { createContext } from "react";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { EvaluationCommentV2 } from "../IterationEngine/types/EvaluationCommentV2";
import { useWildfireEvaluationComment } from "../IterationEngine/Comment/useWildfireEvaluationComment";

interface EvaluationCommentContextProps {
  comments: EvaluationCommentV2[];
  setComments?: (comments: EvaluationCommentV2[]) => void;
  createComment: (
    wildfireEvaluationId: string,
    comment: string,
    evaluationFieldKey: string,
    evaluationFieldLabel: string
  ) => Promise<void>;
  deleteComment: (
    wildfireEvaluationId: string,
    commentId: string,
    evaluationFieldKey: string
  ) => Promise<void>;

  selectedField?: BaseFieldProps<any>;
  setSelectedKeyAndGetComments?: (
    wildfireEvaluationId?: string,
    fieldKey?: string
  ) => Promise<void>;
  setSelectedFieldAndGetComments?: (
    wildfireEvaluationId: string,
    fieldProps: BaseFieldProps<any>
  ) => Promise<void>;
  selectedKey: string | undefined;
}

export const WildfireEvaluationCommentContext =
  createContext<EvaluationCommentContextProps>({
    comments: [],
    selectedKey: undefined,
    createComment: async () => {
      console.warn("createComment not implemented");
    },
    deleteComment: async () => {
      console.warn("deleteComment not implemented");
    },
  });

export const WildfireEvaluationCommentProvider: React.FC<
  React.PropsWithChildren<{}>
> = ({ children }) => {
  const {
    comments,
    setComments,
    createComment,
    deleteComment,
    selectedKey,
    selectedField,
    setSelectedFieldAndGetComments,
    setSelectedKeyAndGetComments,
  } = useWildfireEvaluationComment();

  return (
    <WildfireEvaluationCommentContext.Provider
      value={{
        comments,
        setComments,
        createComment,
        deleteComment,
        selectedKey,
        selectedField,
        setSelectedFieldAndGetComments,
        setSelectedKeyAndGetComments,
      }}
    >
      {children}
    </WildfireEvaluationCommentContext.Provider>
  );
};
