import { dialogStore } from "@ucl/library";
import { DialogProps } from "@ucl/library/lib/components/Dialogs/Dialog/Dialog";
import { AdminUpdateForm } from "./AdminUpdateForm";
import "../styles.scss";

const dialogIdBase = "wildfire-admin-update-dialog";

const getDialogId = (wildfireEvaluationId: string): string =>
  `${dialogIdBase}_${wildfireEvaluationId}`;

const buildAdminUpdateDialogProps = (
  wildfireEvaluationId: string,
  wildfireApplicationOwnerId: string,
  getWildfireApplication: () => Promise<void>
): DialogProps => {
  return {
    id: getDialogId(wildfireEvaluationId),
    className: dialogIdBase,
    header: "Admin Update",
    allowDragging: false,
    content: () => (
      <AdminUpdateForm
        wildfireEvaluationId={wildfireEvaluationId}
        wildfireApplicationOwnerId={wildfireApplicationOwnerId}
        getWildfireApplication={getWildfireApplication}
      />
    ),
  };
};

export const openAdminUpdateDialog = (
  wildfireEvaluationId: string,
  wildfireApplicationOwnerId: string,
  getWildfireApplication: () => Promise<void>
) =>
  dialogStore.openDialog(
    buildAdminUpdateDialogProps(
      wildfireEvaluationId,
      wildfireApplicationOwnerId,
      getWildfireApplication
    )
  );

export const closeAdminUpdateDialog = (wildfireEvaluationId: string) =>
  dialogStore.closeDialog(getDialogId(wildfireEvaluationId));
