import React, { useEffect, useState } from "react";
import "./styles.scss";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../AppInsights";
import { useParams } from "react-router";
import { Observer } from "mobx-react";
import { ExternalEvaluationBidSummary } from "../types/evaluation/Evaluation";
import ExternalEvaluationBidSummaryContainer from "../components/Evaluation/EvaluationBidSummary/ExternalEvaluationBidSummaryContainer";
import ExternalPageHeader from "../components/Header/ExternalPageHeader";
import { evaluationBidApiClient } from "../lib/apiClients/evaluation/evaluationBidApiClient";

export interface ExternalEvaluationSummaryProps {}

const ExternalEvaluationSummaryPageComponent: React.FC<
  ExternalEvaluationSummaryProps
> = () => {
  const { id } = useParams<{ id: string }>();
  const [isInitialized, setIsInitialized] = useState(false);

  const [evaluationSummary, setEvaluationSummary] =
    useState<ExternalEvaluationBidSummary>();

  const initialize = async () => {
    const foundEvaluation =
      await evaluationBidApiClient.getExternalCommercialEvaluationSummary(id);

    setEvaluationSummary(foundEvaluation);
    setIsInitialized(true);
  };

  useEffect(() => {
    initialize();

    return () => {
      setIsInitialized(false);
    };
  }, []);
  return (
    <div className="fortified-page">
      <Observer>
        {() => (
          <>
            {isInitialized && evaluationSummary && (
              <>
                <ExternalPageHeader
                  name={"Evaluation Bid Information"}
                  address={evaluationSummary.buildingAddress_AsString}
                  fortifiedId={evaluationSummary.fortifiedId}
                  buildingName={evaluationSummary.buildingName}
                />
                <div className="content">
                  <ExternalEvaluationBidSummaryContainer
                    externalEvaluationSummary={evaluationSummary}
                  />
                </div>
              </>
            )}
          </>
        )}
      </Observer>
    </div>
  );
};

export const ExternalEvaluationSummaryPage = withAITracking(
  reactPlugin,
  ExternalEvaluationSummaryPageComponent,
  "ExternalEvaluationSummaryPage",
  "tracking-container"
);
