import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import useFeatureToggleAPI from "../../common/Components/FeatureToggle/hook/useFeatureToggleAPI";
import { FeatureToggleSet } from "../../common/Components/FeatureToggle/FeatureToggles";
import wildfireBaseAPIClient from "../lib/wildfireBaseApiClient";

interface WildfireFeatureToggleContextProps {
  featureToggles: FeatureToggleSet | null;
  isWildfireEnabled: boolean;
}

interface FeatureToggleProviderProps {
  children: ReactNode;
}

const WildfireFeatureToggleContext =
  createContext<WildfireFeatureToggleContextProps>({
    featureToggles: null,
    isWildfireEnabled: false,
  });

export const WildfireFeatureToggleProvider: React.FC<
  FeatureToggleProviderProps
> = (props) => {
  const [featureToggles, setFeatureToggles] = useState<FeatureToggleSet | null>(
    null
  );
  const { getFeatureToggleSet } = useFeatureToggleAPI(wildfireBaseAPIClient);

  useEffect(() => {
    const init = async () => {
      if (!featureToggles) {
        const toggles = await getFeatureToggleSet();
        setFeatureToggles(toggles);
      }
    };

    init();
  }, [getFeatureToggleSet, featureToggles]);

  const isWildfireEnabled = featureToggles?.Wildfire ?? false;

  return (
    <WildfireFeatureToggleContext.Provider
      value={{
        featureToggles,
        isWildfireEnabled,
      }}
    >
      {props.children}
    </WildfireFeatureToggleContext.Provider>
  );
};

export const useWildfireFeatureToggle = () =>
  useContext(WildfireFeatureToggleContext);
