import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { RelativityTooltip, dialogStore } from "@ucl/library";
import { EvaluationStatus } from "../../../types/WildfireApplication/WildfireApplication";
import { getCloseDialogProps } from "../../Dialogs/Close/CloseDialog";
import { openWildfireRejectionDialog } from "../../Dialogs/Rejection/WildfireRejectionDialog";
import { WildfireRejectionFormModel } from "../../Dialogs/Rejection/WildfireRejectionForm";

interface WildfireRejectActionButtonProps {
  wildfireApplicationId: string;
  hasFinalDetermination: boolean;
  status: EvaluationStatus;
  reject: (form: WildfireRejectionFormModel) => Promise<void>;
  isRejectLoading: boolean;
  isEvaluationClaimedByCurrentUser: boolean;
  isWithdrawn: boolean;
  evaluationId: string;
  hasWithdrawPermission: boolean;
  auditorNotes: string;
  refreshWildfireEvaluation: () => Promise<void>;
  isRejected: boolean;
}

export const WildfireRejectActionButton: React.FC<
  WildfireRejectActionButtonProps
> = (props: WildfireRejectActionButtonProps) => {
  const shouldDisable =
    props.isRejected ||
    !props.isEvaluationClaimedByCurrentUser ||
    props.isWithdrawn;

  const toolTipText = props.hasFinalDetermination
    ? `Evaluation is already ${props.status}`
    : !props.isEvaluationClaimedByCurrentUser
    ? "Claim Evaluation to Reject"
    : props.isWithdrawn
    ? "Evaluation is closed"
    : "Reject";

  return (
    <RelativityTooltip shouldShowTooltip={shouldDisable} content={toolTipText}>
      <Button
        minimal
        className="wildfire-reject-evaluation-button"
        text="Reject"
        disabled={shouldDisable}
        loading={props.isRejectLoading}
        intent={Intent.DANGER}
        rightIcon={IconNames.CrossCircle}
        onClick={async () => {
          if (props.status === "IBHS Eligibility Review") {
            dialogStore.openDialog(
              getCloseDialogProps(
                props.evaluationId,
                props.refreshWildfireEvaluation,
                props.hasWithdrawPermission,
                props.auditorNotes
              )
            );
          } else {
            openWildfireRejectionDialog(
              props.evaluationId,
              props.wildfireApplicationId,
              props.reject
            );
          }
        }}
      />
    </RelativityTooltip>
  );
};
