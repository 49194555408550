export enum FormChecklistStatus {
  Unsubmitted,
  NextUp,
  Submitted,
}

export interface FormChecklistPart<FormPartType> {
  formPart: FormPartType;
  displayName: string;
  requiredFieldCount?: number;
  disabled?: boolean;
}
