import { SecondaryButton } from "@ucl/library";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowRight } from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";
import classNames from "classnames";

interface WildfireSubmitButtonProps {
  buttonText?: string;
  isLoading?: boolean;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
}

const WildfireSubmitButton: React.FC<WildfireSubmitButtonProps> = (props) => {
  const buttonText = !!props.buttonText ? props.buttonText : "Save";

  return (
    <div className={classNames(["wildfire-submit-button", props.className])}>
      <SecondaryButton disabled={props.disabled} onClick={props.onClick}>
        <div>{buttonText}</div>
        <FontAwesomeIcon icon={faLongArrowRight} />
      </SecondaryButton>
    </div>
  );
};

export default WildfireSubmitButton;
