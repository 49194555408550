export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role?: UserRoleType;
  canClaimEvaluations: boolean;
  canUpdateEvaluatorFromAdmin: boolean;
}

export enum UserRole {
  "FC/FMF Applicant" = 1,
  "FC/FMF Evaluator" = 2,
  "FC/FMF Auditor" = 3,
  "FH Evaluator" = 4,
  "FH Auditor" = 5,
  "FH Roofer" = 6,
  "FH Direct Submit Roofer" = 7,
  "FH Engineer" = 8,
  "FH Contractor" = 9,
  "IBHS Admin" = 10,
  "IBHS Partner" = 11,
  "FH Evaluator Admin" = 12,
  "Relativity Admin" = 13,
  "NCIUA Partner" = 14,
  "FC/FMF Admin" = 15,
  "FH Auditor Trainee" = 16,
  "FH Applicant" = 17,
  "WFP Applicant" = 300,
  "WFP Admin" = 301,
  "WFP Evaluator" = 302,
}

export type UserRoleType =
  | "FC/FMF Applicant"
  | "FC/FMF Evaluator"
  | "FC/FMF Auditor"
  | "FH Evaluator"
  | "FH Auditor"
  | "FH Roofer"
  | "FH Direct Submit Roofer"
  | "FH Engineer"
  | "FH Contractor"
  | "IBHS Admin"
  | "IBHS Partner"
  | "FH Evaluator Admin"
  | "Relativity Admin"
  | "NCIUA Partner"
  | "FC/FMF Admin"
  | "FH Auditor Trainee"
  | "FH Applicant"
  | "WFP Applicant"
  | "WFP Admin"
  | "WFP Evaluator";
