import React from "react";
import {
  Filter,
  GridComponent,
  GridModel,
  InfiniteScroll,
  Inject,
  Page,
  Reorder,
  Sort,
  Toolbar,
  Resize,
  Group,
  Search,
  ExcelExport,
  PredicateModel,
  SortDescriptorModel,
} from "@syncfusion/ej2-react-grids";
import "./styles.scss";
import { DefaultHtmlAttributes } from "@syncfusion/ej2-react-base";
import FortifiedGridColumn from "../../types/fortifiedGrid/FortifiedGridColumn";
import {
  FortifiedGridSetup,
  fortifiedGridStore,
} from "../../stores/FortifiedGridStore";
import {
  TooltipComponent,
  TooltipEventArgs,
} from "@syncfusion/ej2-react-popups";
import { FortifiedGridModel } from "../../types/fortifiedGrid/FortifiedGridModel";
import { useProductTypeHandler } from "../../providers/ProductTypeHandlerProvider";

export interface FortifiedGridProps<T extends Object> {
  dataSource: string;
  columnsConfiguration: Readonly<FortifiedGridColumn[]>;
  filterConfiguration?: Readonly<PredicateModel[]>;
  sortConfiguration?: Readonly<SortDescriptorModel[]>;
  gridConfiguration?: Readonly<FortifiedGridModel<T>>;
  className?: string;
}

export const FortifiedGrid = <T extends object>(
  props: FortifiedGridProps<T>
) => {
  const { getAccessToken } = useProductTypeHandler();
  const [ready, setReady] = React.useState<boolean>(false);
  const [initialized, setInitialized] = React.useState<boolean>(false);
  const [gridSettings, setGridSettings] = React.useState<
    GridModel & DefaultHtmlAttributes
  >();

  const [tooltipText, setTooltipText] = React.useState<string>();
  const gridRef = React.useRef<GridComponent | null>(null);

  // Setup
  React.useEffect(() => {
    setup();

    return () => {
      fortifiedGridStore.reset();
    };
  }, []);

  // Initialize
  React.useEffect(() => {
    if (!!gridRef.current && !initialized) {
      fortifiedGridStore.initialize(gridRef.current);
      setInitialized(true);
    }
  });

  // Setup Method
  const setup = async () => {
    const accessToken = await getAccessToken();
    if (accessToken) {
      const gridModel = fortifiedGridStore.setup({
        dataSource: props.dataSource,
        accessToken: accessToken,
        columnsConfiguration: props.columnsConfiguration,
        filterConfiguration: props.filterConfiguration,
        sortConfiguration: props.sortConfiguration,
        gridConfiguration: props.gridConfiguration,
      } as FortifiedGridSetup<T>);

      setGridSettings(gridModel);
      setReady(true);
    }
  };
  // Set Tooltip on Column hover
  const beforeRender = (args: TooltipEventArgs) => {
    // Only show a tooltip for long character-length column values.
    if (!args.target.innerText || args.target.innerText.length < 25) {
      args.cancel = true;
    }

    // Replace double quotes with HTML entity &quot. To avoid breaking the tooltip.
    const escapedText = args.target.innerText.replace(/"/g, "&quot;");

    setTooltipText(escapedText);
  };

  return (
    <div className="fortified-grid-container">
      {ready && (
        <TooltipComponent
          id="Tooltip"
          target=".e-rowcell"
          beforeRender={beforeRender}
          content={tooltipText}
          className={props.className}
        >
          <GridComponent
            ref={gridRef}
            className="fortified-grid"
            {...gridSettings}
          >
            <Inject
              services={[
                Page,
                Sort,
                Filter,
                Group,
                Toolbar,
                Reorder,
                Resize,
                InfiniteScroll,
                Search,
                ExcelExport,
              ]}
            />
          </GridComponent>
        </TooltipComponent>
      )}
      {!ready && <></>}
    </div>
  );
};
