import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { EvaluationFormModel } from "../EvaluationFormModel";
import { get } from "lodash";

const modelName = "Evaluation";
const subModelName = "WildfireApplication";
const formName = "OtherFieldEvaluationForm";
const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

// Part 1: Other Structures/Outbuildings
const otherStructuresAndOutbuildingsFields = (
  form: EvaluationFormModel,
  isIEBuilder?: boolean,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("StructuresAndOutbuildingsPhotosFolder"),
    defaultToUpload: !isIEBuilder,
    errorMessages: get(
      errors,
      "structuresAndOutbuildingsPhotosFolder.FileCount"
    ),
    legacyEvaluationShouldMakeOptional:
      form.legacyEvaluationSource === "Legacy Wildfire" &&
      !form.areLegacyFileUploadersRequired,
  } as WildfireBoxContentExplorerProps,
  buildFieldKey("AccessoryStructuresDistanceOrAbsent"),
  buildFieldKey("MaxThreeLargeAccessoriesNearHome"),
  buildFieldKey("HotTubPlacementCompliance"),
  buildFieldKey("PropaneTankPlacementAndSafety"),
  buildFieldKey("AccessoryStructure1ComplianceWithHome"),
  buildFieldKey("AccessoryStructure2ComplianceWithHome"),
  buildFieldKey("AccessoryStructure3ComplianceWithHome"),
  {
    fieldKey: buildFieldKey("MiscPhotosFolder"),
    defaultToUpload: !isIEBuilder,
    errorMessages: get(errors, "miscPhotosFolder.FileCount"),
    legacyEvaluationShouldMakeOptional:
      form.legacyEvaluationSource === "Legacy Wildfire" &&
      !form.areLegacyFileUploadersRequired,
  } as WildfireBoxContentExplorerProps,
];
const otherStructuresAndOutbuildingsRequiredFields = [
  buildFieldKey("StructuresAndOutbuildingsPhotosFolder"),
  buildFieldKey("AccessoryStructuresDistanceOrAbsent"),
  buildFieldKey("MaxThreeLargeAccessoriesNearHome"),
  buildFieldKey("AccessoryStructure1ComplianceWithHome"),
  buildFieldKey("AccessoryStructure2ComplianceWithHome"),
  buildFieldKey("AccessoryStructure3ComplianceWithHome"),
  buildFieldKey("HotTubPlacementCompliance"),
  buildFieldKey("PropaneTankPlacementAndSafety"),
];

export const otherFieldEvaluationOtherStructuresAndOutbuildingsBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        otherStructuresAndOutbuildingsFields(form, false, errors),
        onValueChange,
        form,
        errors,
        otherStructuresAndOutbuildingsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};

// Full form builder with all parts included
export const otherFieldEvaluationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        [...otherStructuresAndOutbuildingsFields(form, false, errors)],
        onValueChange,
        form,
        errors,
        [...otherStructuresAndOutbuildingsRequiredFields],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const wieOtherFieldEvaluationFormBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<EvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<EvaluationFormModel>(
        modelName,
        [...otherStructuresAndOutbuildingsFields(form, true, errors)],
        onValueChange,
        form,
        errors,
        [...otherStructuresAndOutbuildingsRequiredFields],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];

    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<EvaluationFormModel>;
};
