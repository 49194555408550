import { useEffect, useRef, useState } from "react";
import usePrevious from "../../../common/customHooks/usePrevious";
import { ChangeRequestStatus } from "../../../foritfied/types/evaluation/ChangeRequest";
import { wildfireUserStore } from "../../stores/WildfireUserStore";
import { useNavigate } from "react-router";
import {
  ChangeRequestsCompleteDialogType,
  openChangeRequestsCompleteDialog,
} from "../Dialogs/ChangeRequestsComplete/ChangeRequestsCompleteDialog";
import { wildfireEvaluationApiClient } from "../../lib/apiClients/application/wildfireApplicationApiClient";
import {
  errorStore,
  genericErrorMessage,
} from "../../../common/Components/Error/ErrorStore";
import { WildfireEvaluationSteps } from "../../../foritfied/types/evaluation/Evaluation";
import { WildfireFieldEvaluationCompliance } from "../../types/WildfireFieldComplianceDTO";
import { wildfireRoutePrefix } from "../../pages/index/wildfireRouteConfig";
import { getThirdLevelNesting } from "./Comment/ChangeRequestNavigator/useChangeRequestNavigator";
import { useContext } from "react";
import { WildfireEvaluationContext } from "../Provider/WildfireEvaluationProvider";
import { EvaluationChangeRequestContext } from "../Provider/EvaluationChangeRequestProvider";
import { WildfireEvaluationCommentContext } from "../Provider/WildfireEvaluationCommentsProvider";
import useWildfirePermissons from "../../customHooks/useWildfirePermissons";
import { ChangeRequestV2 } from "./types/ChangeRequestV2";
import {
  WildfireDesignationLevel,
  WildfireFieldSchemaType,
} from "../../customHooks/useFieldSchemaFactory";
import { get, isNil } from "lodash";
import { ComplianceTypes } from "../../types/ComplianceTypes";

export const BaseTabId = "Base";
export const PlusTabId = "Plus";

const useWildfireIterationEngine = (evaluationId?: string) => {
  const {
    wildfireEvaluation,
    refreshWildfireEvaluation,
    sendForward,
    isSendForwardLoading,
    approve,
    isApproveLoading,
    reject,
    isRejectLoading,
    requestChanges,
    regenerateCertificate,
    downloadCertificate,
    isRequestChangesLoading,
  } = useContext(WildfireEvaluationContext);

  const {
    comments,
    createComment,
    deleteComment,
    setSelectedFieldAndGetComments,
    setSelectedKeyAndGetComments,
    selectedField,
    selectedKey,
  } = useContext(WildfireEvaluationCommentContext);

  const {
    allChangeRequests,
    resolvedAllChangeRequests,
    getChangeRequests,
    getEvaluationFieldKeysForAllUnresolvedChangeRequests,
    hasNoUnaddressedChangeRequests,
    hasOneNewChangeRequest,
    unresolvedChangeRequests,
    longestOutstandingChangeRequest,
  } = useContext(EvaluationChangeRequestContext);

  const { canEditForm } = useWildfirePermissons(
    wildfireEvaluation?.wildfireApplication.step
  );
  const navigate = useNavigate();
  const prevChangeRequests = usePrevious(allChangeRequests);

  const [formFieldEvaluationDecisioning, setFormFieldEvaluationDecisioning] =
    useState<WildfireFieldEvaluationCompliance>();

  const [
    selectedIterationEngineFormTabId,
    setSelectedIterationEngineFormTabId,
  ] = useState<string>(BaseTabId);

  const selectedIterationEngineFormTabRequirePlusFields =
    selectedIterationEngineFormTabId == PlusTabId;

  const hasSetInitialTab = useRef(false);

  // Assuming the lowest step if wildfireEvaluation.step is undefined
  const currentStep = wildfireEvaluation?.step ?? Number.MIN_SAFE_INTEGER;

  const isIBHSReviewStep =
    currentStep === WildfireEvaluationSteps.WildfireApplicationIBHSReview;

  // Base tab that's always present
  const baseTabs = [{ id: BaseTabId, label: "Base" }];
  const additionalTabs = [{ id: PlusTabId, label: "Plus" }];
  const formTabs =
    currentStep < WildfireEvaluationSteps.WildfireApplicationIBHSReview
      ? baseTabs
      : [...baseTabs, ...additionalTabs];

  useEffect(() => {
    if (unresolvedChangeRequests.length === 0 || hasSetInitialTab.current) {
      return;
    }

    const initialTab = getInitialTabFromChangeRequests(
      unresolvedChangeRequests
    );

    setSelectedIterationEngineFormTabId(initialTab);
    hasSetInitialTab.current = true;
  }, [allChangeRequests]);

  useEffect(() => {
    if (!evaluationId) {
      return;
    }

    handleChangeRequestLogic();

    fetchFormFieldEvaluationDecisioning(evaluationId);
  }, [allChangeRequests, selectedIterationEngineFormTabId]);

  const getInitialTabFromChangeRequests = (
    unresolvedChangeRequests: ChangeRequestV2[]
  ): string => {
    return unresolvedChangeRequests.length > 0
      ? getThirdLevelNesting(unresolvedChangeRequests[0].evaluationFieldKey) ||
          BaseTabId
      : BaseTabId;
  };

  const fetchFormFieldEvaluationDecisioning = async (evaluationId: string) => {
    try {
      const res = await wildfireEvaluationApiClient.getFormFieldDecisioning(
        evaluationId
      );
      setFormFieldEvaluationDecisioning(res);
    } catch {
      errorStore.setErrorMessage(genericErrorMessage);
    }
  };

  const handleChangeRequestLogic = () => {
    // Logic related to handling new or resolved change requests
    if (
      wildfireUserStore.isWildfireApplicant &&
      hasNewChangeRequestsResolved()
    ) {
      openChangeRequestsCompleteDialogForApplicant();
    } else if (
      wildfireUserStore.isWildfireAdmin &&
      hasAllChangeRequestsResolved()
    ) {
      openChangeRequestsCompleteDialogForAdmin();
    }
  };

  const hasNewChangeRequestsResolved = () => {
    const hadNewChangeRequest = prevChangeRequests?.some(
      (cr) => cr.status === ChangeRequestStatus.New
    );
    const hasNoNewChangeRequests = !allChangeRequests.some(
      (cr) => cr.status === ChangeRequestStatus.New
    );
    return hadNewChangeRequest && hasNoNewChangeRequests;
  };

  const hasAllChangeRequestsResolved = () => {
    const hadUnresolvedChangeRequest =
      prevChangeRequests &&
      !prevChangeRequests.every(
        (cr) => cr.status === ChangeRequestStatus.Resolved
      );

    return hadUnresolvedChangeRequest && resolvedAllChangeRequests;
  };

  const openChangeRequestsCompleteDialogForApplicant = () => {
    evaluationId &&
      openChangeRequestsCompleteDialog(
        evaluationId,
        ChangeRequestsCompleteDialogType.ApplicantDialog,
        async () => {
          navigate(`${wildfireRoutePrefix}/board`);
        }
      );
  };

  const openChangeRequestsCompleteDialogForAdmin = () => {
    evaluationId &&
      !isIBHSReviewStep &&
      openChangeRequestsCompleteDialog(
        evaluationId,
        ChangeRequestsCompleteDialogType.AdminDialog,
        sendForward
      );
  };

  const getChangeRequestsForTab = (
    wildfireFieldSchema: WildfireFieldSchemaType,
    isPlus?: boolean
  ) => {
    return allChangeRequests.filter(
      (cr) =>
        (get(wildfireFieldSchema, cr.evaluationFieldKey)?.designationLevel ===
          WildfireDesignationLevel.Plus || false) === isPlus
    );
  };

  const getCountOfNonCompliantFields = (
    wildfireFieldSchema: WildfireFieldSchemaType,
    isPlus?: boolean
  ) => {
    if (isNil(isPlus) || isNil(formFieldEvaluationDecisioning)) {
      return undefined;
    }

    const count = Object.keys(formFieldEvaluationDecisioning).filter(
      (key) =>
        (wildfireFieldSchema[key]?.designationLevel ===
          WildfireDesignationLevel.Plus || false) === isPlus &&
        formFieldEvaluationDecisioning &&
        formFieldEvaluationDecisioning[key].complianceType !==
          ComplianceTypes.Compliant &&
        formFieldEvaluationDecisioning[key].complianceType !==
          ComplianceTypes.NA
    ).length;

    return count > 0 ? count : undefined;
  };

  return {
    selectedIterationEngineFormTabId,
    setSelectedIterationEngineFormTabId,
    formFieldEvaluationDecisioning,
    formTabs,
    wildfireEvaluation,
    refreshWildfireEvaluation,
    sendForward,
    isSendForwardLoading,
    approve,
    isApproveLoading,
    reject,
    isRejectLoading,
    requestChanges,
    regenerateCertificate,
    downloadCertificate,
    isRequestChangesLoading,
    comments,
    createComment,
    deleteComment,
    setSelectedFieldAndGetComments,
    setSelectedKeyAndGetComments,
    selectedField,
    selectedKey,
    allChangeRequests,
    resolvedAllChangeRequests,
    getChangeRequests,
    getEvaluationFieldKeysForAllUnresolvedChangeRequests,
    hasNoUnaddressedChangeRequests,
    hasOneNewChangeRequest,
    canEditForm,
    unresolvedChangeRequests,
    fetchFormFieldEvaluationDecisioning,
    selectedIterationEngineFormTabRequirePlusFields,
    getCountOfNonCompliantFields,
    getChangeRequestsForTab,
    longestOutstandingChangeRequest,
  };
};

export default useWildfireIterationEngine;
