import { Classes, Dialog, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { EvaluationStep } from "../../types/evaluation/Evaluation";
import { DialogFooter } from "./DialogFooter";
import { BaseDialogProps } from "./types";

export interface AuditorApprovalDialogProps extends BaseDialogProps {
  step?: EvaluationStep;
  isComplexCommercialEvaluation?: boolean;
}

export const AuditorApprovalDialog: React.FC<AuditorApprovalDialogProps> = ({
  isOpen,
  step,
  isComplexCommercialEvaluation,
  closeDialog,
  submitDialog,
}) => {
  const getDialogBody = () => {
    if (step === EvaluationStep.FinalReview) {
      if (isComplexCommercialEvaluation === true) {
        return "Have you verified that ALL the building addresses and designation levels are correct? Are you sure you want to approve?";
      } else {
        return "Have you verified that the building address and the designation level are correct? Are you sure you want to approve?";
      }
    }
    return "Are you sure you want to approve?";
  };

  return (
    <Dialog isOpen={isOpen} hasBackdrop={false}>
      <div className={Classes.DIALOG_HEADER}>
        <Icon icon={IconNames.TICK_CIRCLE} /> Approve Confirmation
      </div>
      <div className={Classes.DIALOG_BODY}>{getDialogBody()}</div>
      <DialogFooter closeDialog={closeDialog} submitDialog={submitDialog} />
    </Dialog>
  );
};
