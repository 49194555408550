import {
  Button,
  Checkbox,
  Classes,
  Dialog,
  FormGroup,
  InputGroup,
  Intent,
  MenuItem,
} from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import React from "react";
import { userApiClient } from "../../lib/apiClients/user/userApiClient";
import { schemaStore } from "../../stores/AppSchemaStore";
import { dialogsViewerStore } from "../../stores/DialogsViewerStore";
import { fortifiedGridStore } from "../../stores/FortifiedGridStore";
import { BaseDialogProps } from "./types";
import { MinimalSelectableLabel } from "../Fields/SelectableLabel/MinimalSelectableLabel";
import { Option } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import "./styles.scss";

const defaultFormValue: UserUpdateDialogFormValue = {
  role: 0,
  firstName: "",
  lastName: "",
  companyName: "",
  isActive: true,
};

interface UserUpdateDialogFormValue {
  role: number;
  firstName: string;
  lastName: string;
  companyName: string;
  isActive: boolean;
}

export interface UserUpdateDialogProps extends BaseDialogProps {}

export const UserUpdateDialog: React.FC<UserUpdateDialogProps> = ({
  isOpen,
  closeDialog,
  submitDialog,
}) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const [formValue, setFormValue] =
    React.useState<UserUpdateDialogFormValue>(defaultFormValue);

  const selectedRole = schemaStore.userRoleOptions.find(
    (o) => o.value === formValue.role
  );

  //Filter Out Wildfire Roles
  const fortifiedRoles = schemaStore.userRoleOptions.filter(
    (o) => o.value !== 300 && o.value !== 301 && o.value !== 302
  );

  React.useEffect(() => {
    if (!!dialogsViewerStore.userUpdateDialogOpenOptions) {
      userApiClient
        .getUserById(dialogsViewerStore.userUpdateDialogOpenOptions.userId)
        .then((user) => {
          if (!!user) {
            const { role, firstName, lastName, companyName, isActive } = user;
            setFormValue({
              role,
              firstName,
              lastName,
              companyName,
              isActive,
            });
          }
        });
    }

    return () => setFormValue(defaultFormValue);
  }, [dialogsViewerStore.userUpdateDialogOpenOptions]);

  return (
    <Dialog isOpen={isOpen} hasBackdrop={false}>
      <div className={Classes.DIALOG_HEADER}>Update User</div>
      <div className={Classes.DIALOG_BODY}>
        <form
          id="update-user-form"
          onSubmit={async (e) => {
            e.preventDefault();

            if (!!dialogsViewerStore.userUpdateDialogOpenOptions) {
              setIsSaving(true);

              await userApiClient
                .updateUserById(
                  dialogsViewerStore.userUpdateDialogOpenOptions.userId,
                  formValue
                )
                .then(() => {
                  fortifiedGridStore.refresh();
                  submitDialog();
                })
                .finally(() => {
                  setIsSaving(false);
                });
            }
          }}
        >
          <FormGroup className="base-field">
            <MinimalSelectableLabel label={"Role"} />
            <Select<Option<number>>
              items={fortifiedRoles}
              itemRenderer={(item, itemProps) => {
                return (
                  <MenuItem
                    className="select__menu-item"
                    key={itemProps.index}
                    text={item.label}
                    active={formValue.role === item.value}
                    onClick={itemProps.handleClick}
                  />
                );
              }}
              onItemSelect={(item) => {
                setFormValue((prev) => ({
                  ...prev,
                  role: item.value,
                }));
              }}
              filterable={false}
              popoverProps={{
                position: "auto",
                minimal: true,
                hasBackdrop: true,
                canEscapeKeyClose: true,
              }}
            >
              <InputGroup readOnly value={selectedRole?.label} />
            </Select>
          </FormGroup>
          <FormGroup className="base-field">
            <MinimalSelectableLabel label={"Name"} />
            <div className="name-field">
              <div className="name-field_input name-field_first-name">
                <InputGroup
                  value={formValue.firstName}
                  onChange={(e) =>
                    setFormValue((prev) => ({
                      ...prev,
                      firstName: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="name-field_input">
                <InputGroup
                  value={formValue.lastName}
                  onChange={(e) =>
                    setFormValue((prev) => ({
                      ...prev,
                      lastName: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </FormGroup>
          <FormGroup className="base-field">
            <MinimalSelectableLabel label={"Company Name"} />
            <InputGroup
              value={formValue.companyName}
              onChange={(e) =>
                setFormValue((prev) => ({
                  ...prev,
                  companyName: e.target.value,
                }))
              }
            />
          </FormGroup>
          <FormGroup className="base-field user-update-active-checkbox">
            <Checkbox
              checked={formValue.isActive}
              onChange={(e) =>
                setFormValue((prev) => ({
                  ...prev,
                  isActive: e.currentTarget.checked,
                }))
              }
            />
            <MinimalSelectableLabel label={"Active"} />
          </FormGroup>
        </form>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          minimal
          intent={Intent.DANGER}
          text={"Cancel"}
          onClick={closeDialog}
        />
        <Button
          intent={Intent.SUCCESS}
          text={"Save"}
          type="submit"
          form="update-user-form"
          loading={isSaving}
        />
      </div>
    </Dialog>
  );
};
