import {
  FieldFormV2,
  FieldFormV2Module,
  FieldSchemaFormBuilderV2,
  Footer,
  AsyncAutoCompleteFieldProps,
  SingleCheckboxFieldProps,
} from "@ucl/library";
import { FC, useRef } from "react";
import { wildfireEvaluationApiClient } from "../../../lib/apiClients/application/wildfireApplicationApiClient";
import { Intent } from "@blueprintjs/core";
import { AppToaster } from "../../../../foritfied/components/Toast/Toast";
import { closeAdminUpdateDialog } from "./AdminUpdateDialog";
import variables from "../../../../common/config/variables";
import { Predicate, Query } from "@syncfusion/ej2-data";
import { getAuthRequestHeader } from "../../../lib/wildfireBaseApiClient";
import { UserReadDTO } from "../../../../foritfied/lib/apiClients/user/types";
import WildfireSubmitButton from "../../Button/DialogFooterButtons/SubmitButton/SubmitButton";
import WildfireSecondaryButton from "../../Button/DialogFooterButtons/SecondaryButton/SecondaryButton";

export interface AdminUpdateFormProps {
  wildfireEvaluationId: string;
  wildfireApplicationOwnerId: string;
  getWildfireApplication: () => Promise<void>;
}

export interface AdminUpdateFormModel {
  updateHomeowner: boolean;
  homeownerId?: string;
}

const adminUpdateFormDefaults: AdminUpdateFormModel = {
  updateHomeowner: false,
  homeownerId: undefined, // TODO: default to the current owner
};

const adminUpdateFormBuilder: FieldSchemaFormBuilderV2<AdminUpdateFormModel> = (
  onValueChange,
  form,
  _
) => {
  return [
    {
      type: "SingleCheckbox",
      fieldName: "updateHomeowner",
      fieldKey: "updateHomeowner",
      text: "Update Homeowner",
      value: form.updateHomeowner,
      onSubmit: function (value) {
        onValueChange({ ...form, updateHomeowner: !!value }, value, this);
      },
    } as SingleCheckboxFieldProps,
    {
      type: "AsyncAutoComplete",
      fieldName: "homeownerSelection",
      fieldKey: "homeownerSelection",
      hidden: !form.updateHomeowner,
      label: "Homeowner",
      placeholder: "Type to search users...",
      noResultsLabel: "No users found",
      apiBaseUrl: variables.apiBaseUrl,
      odataUrl: `${variables.apiBaseUrl}odata/userodata`,
      getAuthRequestHeader,
      fieldNames: ["id", "fullName"],
      autoCompleteSettings: {
        fields: { text: "fullName", value: "id" },
        itemTemplate: "<table><tr><td>${fullName}</td></tr><table>",
        query: new Query()
          .select(["id", "fullName"])
          .where(new Predicate("isActive", "equal", true))
          .take(20),
      },
      sortBy: "lastName",
      searchBy: (searchText: string) =>
        new Predicate(
          // Build our own fullName since it's a computed property and can't be queried directly
          "concat(concat(firstName, ' '), lastName)",
          "contains",
          searchText,
          true
        ),
      value: form.homeownerId,
      onSubmit: function (value) {
        // Workaround because AsyncAutocompleteFieldValue type is not accurate
        // It actually returns the entire OData record (for user, in this case)
        // When we expect it to just be the value of the "id" field
        const userSelection = value as unknown as UserReadDTO;
        onValueChange({ ...form, homeownerId: userSelection?.id }, value, this);
      },
    } as AsyncAutoCompleteFieldProps,
  ];
};

export const AdminUpdateForm: FC<AdminUpdateFormProps> = (
  props: AdminUpdateFormProps
) => {
  const adminUpdateFormRef =
    useRef<FieldFormV2Module<AdminUpdateFormModel>>(null);

  return (
    <>
      <FieldFormV2<AdminUpdateFormModel>
        ref={adminUpdateFormRef}
        value={adminUpdateFormDefaults}
        fieldSchemaFormBuilder={adminUpdateFormBuilder}
        onFormSubmit={async (form) => {
          await wildfireEvaluationApiClient
            .submitAdminUpdate(props.wildfireEvaluationId, form)
            .then(async () => {
              AppToaster.show({
                message: "Successfully updated application",
                intent: Intent.SUCCESS,
              });
              closeAdminUpdateDialog(props.wildfireEvaluationId);
              await props.getWildfireApplication();
            })
            .catch((error) => {
              AppToaster.show({
                message: "Failed to update application",
                intent: Intent.DANGER,
              });
              console.error(error);
            });
        }}
      />
      <Footer>
        <WildfireSecondaryButton
          className="wildfire-admin-update-dialog-cancel-button"
          buttonText="Cancel"
          onClick={() => closeAdminUpdateDialog(props.wildfireEvaluationId)}
        />
        <WildfireSubmitButton
          className="wildfire-admin-update-dialog-submit-button"
          buttonText="Submit"
          onClick={() => adminUpdateFormRef.current?.submit()}
        />
      </Footer>
    </>
  );
};
