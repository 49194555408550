export enum YesOrNoSelection {
  Yes = 1,
  No = 2,
}

export enum EvaluationCompanies {
  IBHS = 1,
  Millennium = 2,
  Madronus = 3,
}

export enum ServiceTypes {
  DIYService = 1,
  FullService = 2,
}

export enum WildfireRoles {
  WildfireApplicant = 300,
  WildfireAdmin = 301,
  WildfireEvaluator = 302,
}

export enum HowDidYouHearAboutUs {
  AlliedDisasterDefense = 1,
  FireAside = 2,
  Madronus = 3,
  Other = 4,
}
