import { Observer } from "mobx-react";
import { permissionStore } from "../../../stores/PermissionStore";
import {
  Button,
  Intent,
  NonIdealState,
  OptionProps,
  RadioGroup,
} from "@blueprintjs/core";
import {
  MessageComponent,
  Severity,
} from "@syncfusion/ej2-react-notifications";
import { EvaluationBidCompanySelectContainer } from "./EvaluationBidCompanySelect";
import { useEffect, useState } from "react";
import {
  EvaluationBid,
  EvaluationCompanyTypes,
  NewEvaluationBid,
  evaluationCompanyDefaults,
} from "../../../types/evaluation/EvaluationBid";
import "./evaluationbid.scss";
import { showSuccessAppToasterWithTitle } from "../../Toast/Toast";
import { evaluationBidApiClient } from "../../../lib/apiClients/evaluation/evaluationBidApiClient";
import { useNavigate } from "react-router";
import { useProductTypeHandler } from "../../../providers/ProductTypeHandlerProvider";
import { PrimaryCard } from "@ucl/library";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";

interface EvaluationContainerProps {
  evaluationId: string;
}

export const EvaluationBidContainer: React.FC<EvaluationContainerProps> = (
  props
) => {
  const navigate = useNavigate();
  const { routePrefix } = useProductTypeHandler();
  const [selectedCompany, setSelectedCompany] =
    useState<EvaluationCompanyTypes>();
  const [selectableOptions, setSelectableOptions] = useState<OptionProps[]>();
  const [evaluationBids, setEvaluationBids] = useState<EvaluationBid[]>([]);

  const [isLoading, setIsloading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const Init = async () => {
    setIsloading(true);
    await evaluationBidApiClient
      .getEvaluationBids(props.evaluationId)
      .then((bids) => {
        setEvaluationBids(bids);

        getSelectableOptions(bids);
        setIsloading(false);
      });
  };

  const getDefaultBid = (
    evaluationCompany: EvaluationCompanyTypes
  ): NewEvaluationBid => {
    return evaluationCompanyDefaults[evaluationCompany];
  };

  const getSelectableOptions = (evaluationBids: EvaluationBid[]) => {
    setSelectableOptions(checkboxOptions(evaluationBids));
  };

  const searchForExisitingBid = (evaluationCompany: EvaluationCompanyTypes) => {
    return evaluationBids.find(
      (bid) => bid.evaluationCompany === evaluationCompany
    );
  };

  const checkboxOptions = (evaluationBids: EvaluationBid[]) =>
    Object.values(evaluationCompanyDefaults).map((companyDefault) => ({
      label: companyDefault.evaluationCompany_AsString,
      value: companyDefault.evaluationCompany,
      disabled: !evaluationBids.find(
        (bid) => bid.evaluationCompany === companyDefault.evaluationCompany
      ),
    }));

  useEffect(() => {
    Init();
    return () => {
      setEvaluationBids([]);
      getSelectableOptions([]);
      setIsloading(false);
    };
  }, []);

  return (
    <Observer>
      {() => {
        return permissionStore.canViewBidsPage ? (
          <>
            <div className="bids-container">
              {!isLoading && (
                <PrimaryCard headerContent={<h3>Request Bids</h3>} columns={1}>
                  <MessageComponent
                    content={`
                              <div>
                                    1. Click the “Send Request” button next to the Evaluator you'd like to send a bid request to.<br>
                                    2. You can send a bid request to multiple companies at once. You can also re-send a bid request at any time by clicking the “Re-send Request” button.<br>
                                    3. Once you have determined which evaluator you want to work with, navigate back to the same page and select the company you've chosen.<br>
                                    4. Click “Submit” to move the application forward and assign your desired Evaluator to the evaluation.<br>
                                    <br>
                                    The following Evaluators are verified FORTIFIED Commercial and Multifamily Evaluators:
                                    <br>
                                    Global Risk Consultants (GRC)
                                    <!--<br>
                                    Paragon Consultants-->
                              </div>
                            `}
                    severity={Severity.Info}
                    showCloseIcon
                  />
                  {Object.values(EvaluationCompanyTypes).map((companyType) => {
                    const evaluationCompanyType =
                      companyType as EvaluationCompanyTypes;

                    const existingBid = searchForExisitingBid(
                      evaluationCompanyType
                    );

                    return (
                      <EvaluationBidCompanySelectContainer
                        key={evaluationCompanyType}
                        evaluationBid={
                          existingBid || getDefaultBid(evaluationCompanyType)
                        }
                        evaluationId={props.evaluationId}
                        refreshBids={Init}
                      />
                    );
                  })}
                  <MessageComponent
                    content={
                      "After sending the request for bid (using the button above), please navigate back to this page once you have selected the Evaluation Company you choose to move forward with and make your selection below."
                    }
                    severity={Severity.Info}
                    showCloseIcon
                  />
                  <div>
                    <RadioGroup
                      options={selectableOptions}
                      inline
                      label={
                        "Which Evaluation Company did you choose to move forward with?"
                      }
                      selectedValue={selectedCompany}
                      onChange={(e) => {
                        if (!!e.currentTarget.value) {
                          const selectedValue = Number(e.currentTarget.value);
                          setSelectedCompany(selectedValue);
                        }
                      }}
                    />
                  </div>
                  <div className="bids-footer">
                    <div>
                      <Button
                        text={"Return to Board"}
                        intent={Intent.NONE}
                        onClick={() => navigate(`${routePrefix}`)}
                      />
                    </div>
                    <div>
                      <Button
                        text={"Submit"}
                        intent={Intent.PRIMARY}
                        disabled={!selectedCompany}
                        loading={isSaving}
                        onClick={async () => {
                          setIsSaving(true);
                          if (selectedCompany) {
                            const existingBid =
                              searchForExisitingBid(selectedCompany);
                            if (existingBid) {
                              await evaluationBidApiClient
                                .acceptEvaluationBid(
                                  props.evaluationId,
                                  existingBid.id
                                )
                                .then(() => {
                                  showSuccessAppToasterWithTitle(
                                    "You have successfully accepted the bid for this evaluation.",
                                    "Bid Request Accepted"
                                  );
                                  navigate(
                                    `${routePrefix}/evaluation/${props.evaluationId}`
                                  );
                                })
                                .finally(() => {
                                  setIsSaving(false);
                                });
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                </PrimaryCard>
              )}
              {isLoading && <Loading />}
            </div>
          </>
        ) : (
          <NonIdealState />
        );
      }}
    </Observer>
  );
};
