import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { useState } from "react";
import { wildfireEvaluationCommentApiClient } from "../../../lib/apiClients/comment/wildfireEvaluationCommentApiClient";
import { EvaluationCommentV2 } from "../types/EvaluationCommentV2";

export const useWildfireEvaluationComment = () => {
  const [comments, setComments] = useState<EvaluationCommentV2[]>([]);

  const [selectedKey, setSelectedKey] = useState<string>();

  const [selectedField, setSelectedField] = useState<BaseFieldProps<any>>();

  const setSelectedKeyAndGetComments = async (
    wildfireEvaluationId?: string,
    fieldKey?: string
  ) => {
    if (!wildfireEvaluationId && !fieldKey) {
      setComments([]);
      setSelectedKey(undefined);
      setSelectedField(undefined);
      return;
    }

    if (!wildfireEvaluationId || !fieldKey) return;

    // Fetch and set comments associated with the fieldKey
    const fetchedComments =
      await wildfireEvaluationCommentApiClient.getEvaluationComments(
        wildfireEvaluationId,
        fieldKey
      );

    setComments(fetchedComments);
    setSelectedKey(fieldKey);
    setSelectedField(undefined);
  };

  const setSelectedFieldAndGetComments = async (
    wildfireEvaluationId: string,
    fieldProps: BaseFieldProps<any>
  ) => {
    if (!wildfireEvaluationId) return;
    if (!fieldProps.fieldKey) return;

    // Fetch and set comments associated with the fieldKey
    const fetchedComments =
      await wildfireEvaluationCommentApiClient.getEvaluationComments(
        wildfireEvaluationId,
        fieldProps.fieldKey
      );

    setComments(fetchedComments);
    setSelectedField(fieldProps);
    setSelectedKey(fieldProps.fieldKey);
  };

  const createComments = async (
    wildfireEvaluationId: string,
    comments: string[],
    evaluationFieldKey: string,
    evaluationFieldLabel: string
  ) => {
    if (!wildfireEvaluationId) {
      throw new Error("Missing Evaluation Field");
    }

    const commentsResult =
      await wildfireEvaluationCommentApiClient.createEvaluationComments(
        wildfireEvaluationId,
        evaluationFieldKey,
        evaluationFieldLabel,
        comments
      );

    setComments(commentsResult);
  };

  const createComment = async (
    wildfireEvaluationId: string,
    comment: string,
    evaluationFieldKey: string,
    evaluationFieldLabel: string
  ) => {
    if (!wildfireEvaluationId) {
      throw new Error("Missing Evaluation Field");
    }

    const comments =
      await wildfireEvaluationCommentApiClient.createEvaluationComment(
        wildfireEvaluationId,
        evaluationFieldKey,
        evaluationFieldLabel,
        comment
      );

    setComments(comments);
  };

  const deleteComment = async (
    wildfireEvaluationId: string,
    commentId: string,
    evaluationFieldKey: string
  ) => {
    if (!wildfireEvaluationId) {
      throw new Error("Missing Evaluation Field");
    }

    await wildfireEvaluationCommentApiClient.deleteEvaluationComment(
      wildfireEvaluationId,
      evaluationFieldKey,
      commentId
    );

    setComments(comments.filter((c) => c.id !== commentId));
  };

  return {
    comments,
    setComments,
    createComment,
    createComments,
    deleteComment,
    selectedKey,
    selectedField,
    setSelectedFieldAndGetComments,
    setSelectedKeyAndGetComments,
  };
};
