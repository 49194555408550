import { PrimaryCard, Schema, SecondaryCard } from "@ucl/library";
import { DashboardNavigationButton } from "../../components/Button/DashboardNavigationButton/DashboardNavigationButton";
import RoofFieldEvaluationForm from "../../components/Forms/RoofFieldEvaluationForm/RoofFieldEvaluationForm";
import "./styles.scss";
import { RoofFieldEvaluationFormParts } from "../../forms/RoofFieldEvaluationForm/RoofFieldEvaluationFormModel";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import {
  FieldSchemaFactoryProvider,
  WildfireSchemaFactoryV2,
} from "../../customHooks/useFieldSchemaFactory";
import { LandscapeFieldEvaluationFormParts } from "../../forms/LandscapeFieldEvaluationForm/LandscapeFieldEvaluationFormModel";
import LandscapeFieldEvaluationForm from "../../components/Forms/LandscapeFieldEvaluationForm/LandscapeFieldEvaluationForm";
import useFieldEvaluationForms from "./useFieldEvaluationForms";
import useRoofFieldEvaluationFormParts from "./useRoofFieldEvaluationFormParts";
import useLandscapeFieldEvaluationFormParts from "./useLandscapeFieldEvaluationFormParts";
import { HomeExteriorFieldEvaluationFormParts } from "../../forms/HomeExteriorFieldEvaluationForm/HomeExteriorFieldEvaluationFormModel";
import useHomeExteriorFieldEvaluationFormParts from "./useHomeExteriorFieldEvaluationFormParts";
import HomeExteriorFieldEvaluationForm from "../../components/Forms/HomeExteriorFieldEvaluationForm/HomeExteriorFieldEvaluationForm";
import FieldEvaluationChecklistProgress from "../../components/FieldEvaluation/fieldEvaluationChecklistProgress";
import { OtherFieldEvaluationFormParts } from "../../forms/OtherFieldEvaluationForm/OtherFieldEvaluationFormModel";
import OtherFieldEvaluationForm from "../../components/Forms/OtherFieldEvaluationForm/OtherFieldEvaluationForm";
import useOtherFieldEvaluationFormParts from "./useOtherFieldEvaluationFormParts";
import useWildfirePermissons from "../../customHooks/useWildfirePermissons";
import { useContext, useEffect, useState } from "react";
import wildfireBaseApiClient from "../../lib/wildfireBaseApiClient";
import { UnsavedChangesBlockerContext } from "../../components/Provider/UnsavedChangesBlockerContext";

export interface FieldEvaluationFormPageProps {
  wildfireEvaluationId: string;
  roofFormPart?: RoofFieldEvaluationFormParts;
  homeExteriorFormPart?: HomeExteriorFieldEvaluationFormParts;
  landscapeFormPart?: LandscapeFieldEvaluationFormParts;
  otherFormPart?: OtherFieldEvaluationFormParts;
}

export const FieldEvaluationFormPage = (
  props: FieldEvaluationFormPageProps
) => {
  const {
    isLoading,
    fieldEvaluationFormModel,
    setFieldEvaluationFormModel,
    otherFieldEvaluationFormPart,
    landscapeFieldEvaluationFormPart,
    homeExteriorFieldEvaluationFormPart,
    roofFieldEvaluationFormPart,
  } = useFieldEvaluationForms(props);

  const {
    roofFieldEvaluationChecklistParts,
    getRoofFieldEvaluationPartChecklistStatus,
  } = useRoofFieldEvaluationFormParts({
    fieldEvalautionFormProps: props,
    fieldEvaluationFormModel,
  });

  const {
    homeExteriorFieldEvaluationChecklistParts,
    getHomeExteriorFieldEvaluationPartChecklistStatus,
  } = useHomeExteriorFieldEvaluationFormParts({
    fieldEvalautionFormProps: props,
    fieldEvaluationFormModel,
  });

  const {
    landscapeFieldEvaluationChecklistParts,
    getLandscapeFieldEvaluationPartChecklistStatus,
  } = useLandscapeFieldEvaluationFormParts({
    fieldEvalautionFormProps: props,
    fieldEvaluationFormModel,
  });

  const {
    otherFieldEvaluationChecklistParts,
    getOtherFieldEvaluationPartChecklistStatus,
  } = useOtherFieldEvaluationFormParts({
    fieldEvalautionFormProps: props,
    fieldEvaluationFormModel,
  });

  const { canEditForm } = useWildfirePermissons(
    fieldEvaluationFormModel?.wildfireApplication.step
  );

  const [wildfireSchemaFactory, setWildfireSchemaFactory] =
    useState<WildfireSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await wildfireBaseApiClient.get<Schema>(`/wildfire/schema`);
    const newSchemaFactory = new WildfireSchemaFactoryV2(schema);
    setWildfireSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!wildfireSchemaFactory) {
      getSchema();
    }
  }, []);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  return (
    <>
      {isLoading || !wildfireSchemaFactory ? (
        <Loading />
      ) : (
        <div className="wildfire-form-page">
          <section className="wildfire-form-progress">
            <DashboardNavigationButton />
            <div className="wildfire-form-progress__steps">
              <FieldEvaluationChecklistProgress
                title="Roof"
                checklistParts={roofFieldEvaluationChecklistParts}
                activeFormPart={roofFieldEvaluationFormPart}
                getChecklistStatus={getRoofFieldEvaluationPartChecklistStatus}
                wildfireEvaluationId={props.wildfireEvaluationId}
                formURL="roof-field-evaluation-form"
              />
              <FieldEvaluationChecklistProgress
                title="Home Exterior"
                checklistParts={homeExteriorFieldEvaluationChecklistParts}
                activeFormPart={homeExteriorFieldEvaluationFormPart}
                getChecklistStatus={
                  getHomeExteriorFieldEvaluationPartChecklistStatus
                }
                wildfireEvaluationId={props.wildfireEvaluationId}
                formURL="home-exterior-field-evaluation-form"
              />
              <FieldEvaluationChecklistProgress
                title="Landscape"
                checklistParts={landscapeFieldEvaluationChecklistParts}
                activeFormPart={landscapeFieldEvaluationFormPart}
                getChecklistStatus={
                  getLandscapeFieldEvaluationPartChecklistStatus
                }
                wildfireEvaluationId={props.wildfireEvaluationId}
                formURL="landscape-field-evaluation-form"
              />
              <FieldEvaluationChecklistProgress
                title="Other"
                checklistParts={otherFieldEvaluationChecklistParts}
                activeFormPart={otherFieldEvaluationFormPart}
                getChecklistStatus={getOtherFieldEvaluationPartChecklistStatus}
                wildfireEvaluationId={props.wildfireEvaluationId}
                formURL="other-field-evaluation-form"
              />
            </div>
          </section>
          <section className="wildfire-form-content">
            {roofFieldEvaluationFormPart && (
              <PrimaryCard
                className="wildfire-form-primary-card"
                headerContent={`WFP Roof Field Evaluation Form: ${
                  roofFieldEvaluationChecklistParts.find((value) => {
                    return value.formPart === roofFieldEvaluationFormPart;
                  })?.displayName
                }`}
              >
                <SecondaryCard
                  className="wildfire-form-secondary-card"
                  hideBody={false}
                  isCollapsible={false}
                >
                  <FieldSchemaFactoryProvider
                    schemaFactory={wildfireSchemaFactory}
                  >
                    <RoofFieldEvaluationForm
                      key={roofFieldEvaluationFormPart}
                      wildfireEvaluationId={props.wildfireEvaluationId}
                      formPart={roofFieldEvaluationFormPart}
                      formModel={fieldEvaluationFormModel}
                      setFormModel={setFieldEvaluationFormModel}
                      disableForm={!canEditForm}
                      setHasUnsavedChanges={setHasUnsavedChanges}
                    />
                  </FieldSchemaFactoryProvider>
                </SecondaryCard>
              </PrimaryCard>
            )}
            {homeExteriorFieldEvaluationFormPart && (
              <PrimaryCard
                className="wildfire-form-primary-card"
                headerContent={`WFP Home Exterior Field Evaluation Form: ${
                  homeExteriorFieldEvaluationChecklistParts.find((value) => {
                    return (
                      value.formPart === homeExteriorFieldEvaluationFormPart
                    );
                  })?.displayName
                }`}
              >
                <SecondaryCard
                  className="wildfire-form-secondary-card"
                  hideBody={false}
                  isCollapsible={false}
                >
                  <FieldSchemaFactoryProvider
                    schemaFactory={wildfireSchemaFactory}
                  >
                    <HomeExteriorFieldEvaluationForm
                      key={homeExteriorFieldEvaluationFormPart}
                      wildfireEvaluationId={props.wildfireEvaluationId}
                      formPart={homeExteriorFieldEvaluationFormPart}
                      formModel={fieldEvaluationFormModel}
                      setFormModel={setFieldEvaluationFormModel}
                      disableForm={!canEditForm}
                      setHasUnsavedChanges={setHasUnsavedChanges}
                    />
                  </FieldSchemaFactoryProvider>
                </SecondaryCard>
              </PrimaryCard>
            )}
            {landscapeFieldEvaluationFormPart && (
              <PrimaryCard
                className="wildfire-form-primary-card"
                headerContent={`WFP Landscape Field Evaluation Form: ${
                  landscapeFieldEvaluationChecklistParts.find((value) => {
                    return value.formPart === landscapeFieldEvaluationFormPart;
                  })?.displayName
                }`}
              >
                <SecondaryCard
                  className="wildfire-form-secondary-card"
                  hideBody={false}
                  isCollapsible={false}
                >
                  <FieldSchemaFactoryProvider
                    schemaFactory={wildfireSchemaFactory}
                  >
                    <LandscapeFieldEvaluationForm
                      key={landscapeFieldEvaluationFormPart}
                      wildfireEvaluationId={props.wildfireEvaluationId}
                      formPart={landscapeFieldEvaluationFormPart}
                      formModel={fieldEvaluationFormModel}
                      setFormModel={setFieldEvaluationFormModel}
                      disableForm={!canEditForm}
                      setHasUnsavedChanges={setHasUnsavedChanges}
                    />
                  </FieldSchemaFactoryProvider>
                </SecondaryCard>
              </PrimaryCard>
            )}
            {otherFieldEvaluationFormPart && (
              <PrimaryCard
                className="wildfire-form-primary-card"
                headerContent={`WFP Other Field Evaluation Form: ${
                  otherFieldEvaluationChecklistParts.find((value) => {
                    return value.formPart === otherFieldEvaluationFormPart;
                  })?.displayName
                }`}
              >
                <SecondaryCard
                  className="wildfire-form-secondary-card"
                  hideBody={false}
                  isCollapsible={false}
                >
                  <FieldSchemaFactoryProvider
                    schemaFactory={wildfireSchemaFactory}
                  >
                    <OtherFieldEvaluationForm
                      key={otherFieldEvaluationFormPart}
                      wildfireEvaluationId={props.wildfireEvaluationId}
                      formPart={otherFieldEvaluationFormPart}
                      formModel={fieldEvaluationFormModel}
                      setFormModel={setFieldEvaluationFormModel}
                      disableForm={!canEditForm}
                      setHasUnsavedChanges={setHasUnsavedChanges}
                    />
                  </FieldSchemaFactoryProvider>
                </SecondaryCard>
              </PrimaryCard>
            )}
          </section>
        </div>
      )}
    </>
  );
};
export default FieldEvaluationFormPage;
