import { Button, Icon, Tag } from "@blueprintjs/core";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../pages/forms/FormChecklist";
import { useNavigate } from "react-router";
import { IconNames } from "@blueprintjs/icons";
import { wildfireRoutePrefix } from "../../pages/index/wildfireRouteConfig";

interface ChecklistProps<T> {
  checklistParts: FormChecklistPart<T>[];
  wildfireEvaluationId: string | number;
  formURL: string;
  title: string;
  activeFormPart: T | undefined;
  getChecklistStatus: (formPart: T) => FormChecklistStatus;
}

const FieldEvaluationChecklistProgress = <T,>(props: ChecklistProps<T>) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="wildfire-form-progress__steps__header">{props.title}</div>
      {props.checklistParts.map((checklistPart, index) => {
        const formPartValue = (checklistPart.formPart as any).valueOf();
        return (
          <div key={formPartValue}>
            <div className="wildfire-form-progress__steps__item">
              <div className="wildfire-form-progress__steps__item__page">
                <Icon
                  icon={(() => {
                    switch (props.getChecklistStatus(checklistPart.formPart)) {
                      case FormChecklistStatus.Unsubmitted:
                        return IconNames.CIRCLE;
                      case FormChecklistStatus.NextUp:
                        return IconNames.RING;
                      case FormChecklistStatus.Submitted:
                        return IconNames.TICK_CIRCLE;
                      default:
                        return IconNames.ERROR;
                    }
                  })()}
                />
                <Button
                  minimal
                  intent="primary"
                  active={props.activeFormPart === checklistPart.formPart}
                  text={checklistPart.displayName}
                  onClick={() =>
                    navigate(
                      `${wildfireRoutePrefix}/${props.wildfireEvaluationId}/${props.formURL}/${formPartValue}`
                    )
                  }
                />
              </div>
              {!!checklistPart.requiredFieldCount &&
                checklistPart.requiredFieldCount > 0 && (
                  <div className="wildfire-form-progress__steps__item__badge">
                    <Tag large={true} round={true}>
                      {checklistPart.requiredFieldCount}
                    </Tag>
                  </div>
                )}
            </div>
            {index !== props.checklistParts.length - 1 && (
              <div className="vertical-line" />
            )}
          </div>
        );
      })}
    </>
  );
};

export default FieldEvaluationChecklistProgress;
